@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@font-face {
  font-family: "Bestigia";
  src: url("../font/Bestigia-Regular.ttf");
}

@font-face {
  font-family: "Bhavuka";
  src: url("../font/Bhavuka-Regular.ttf");
}

html,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
D h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Ubuntu", sans-serif;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: var(--background-body);
  color: var(--primary-color);
  font-size: 16px;
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote,
q {
  quotes: none;
}

a img {
  border: 0;
}

img {
  max-width: 100%;
  height: auto;
}

select {
  max-width: 100%;
}

body,
button,
input,
select,
textarea {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  overflow-y: auto;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

.text-p,
p {
  font-weight: 400;
}

strong,
b,
cite {
  font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted #e0e0e0;
  cursor: help;
}

mark,
ins {
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

address {
  font-style: italic;
  margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
  margin: 20px 0;
  padding: 4px 12px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  overflow-x: auto;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  border-radius: 0;
  height: auto;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

hr {
  margin-bottom: 20px;
  border: dashed 1px #ccc;
}

ul,
ol {
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

li {
  list-style: none;
}

ul li,
ol li {
  padding: 0.1em 0;
}

dl,
dd {
  margin: 0 0 20px;
}

dt {
  font-weight: bold;
}

del,
.disable {
  text-decoration: line-through;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

table,
th,
td {
  border: 1px solid #343444;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 30px;
  table-layout: fixed;
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: bold;
}

td {
  border-width: 0 1px 1px 0;
}

th,
td {
  padding: 8px 12px;
}

embed,
object,
video {
  margin-bottom: 20px;
  max-width: 100%;
  vertical-align: middle;
}

p > embed,
p > iframe,
p > object,
p > video {
  margin-bottom: 0;
}

button,
input {
  line-height: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  vertical-align: baseline;
}

input,
textarea,
select {
  font-size: 14px;
  max-width: 100%;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}

textarea {
  overflow: auto;
  vertical-align: top;
  height: 138px;
}

input[type="checkbox"] {
  display: inline;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: button;
  appearance: button;
  border: 0;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  width: 14px;
  height: 14px;
  margin-right: 11px;
  cursor: pointer;
  vertical-align: sub;
}

input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
  box-shadow: 0 0 0px 1000px #f7f7f7 inset;
}

input[type="search"] {
  outline: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.wpcf7-form select,
.wpcf7-form textarea,
.wpcf7-form input {
  margin-bottom: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
}

.select {
  position: relative;
  overflow: hidden;
}
.select ::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f078";
  font-size: 14px;
  right: 10px;
  font-weight: 600;
  color: #1f1f2c;
  display: block;
  position: absolute;
  background: 0 0;
  top: 50%;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

select option {
  font-size: 15px;
  line-height: 24px;
  color: #1f1f2c;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  border: 1px solid var(--primary-color21);
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  line-height: 26px;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  background-color: transparent;
  color: var(--primary-color2);
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  border: 1px solid #8a8aa0;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.7) !important;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #ff008e;
  color: #fff;
  border-radius: 50px;
  padding: 17px 50px;
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover {
  outline: 0;
  background: #ff008e;
  color: #fff;
}

::-webkit-input-placeholder {
  color: var(--primary-color2);
}

:-moz-placeholder {
  color: var(--primary-color2);
}

::-moz-placeholder {
  color: var(--primary-color2);
  opacity: 1;
}

.error {
  font-size: 16px;
  color: red;
  margin-bottom: 10px;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

:-ms-input-placeholder {
  color: var(--primary-color2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 70px;
  line-height: 80px;
}

h2 {
  font-size: 44px;
  line-height: 56px;
}

h3 {
  font-size: 40px;
  line-height: 56px;
}

h4 {
  font-size: 36px;
  line-height: 44px;
}

h5 {
  font-size: 30px;
  line-height: 42px;
}

h6 {
  font-size: 24px;
  line-height: 32px;
}

.msg-success {
  background: linear-gradient(to right, #e250e5, #4b50e6, #e250e5);
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 700;
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  color: rgba(0, 0, 0, 0);
  transition: color 0.2s ease-in-out;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

label.error {
  color: red;
}

.swiper-button-prev,
.swiper-button-next {
  width: 33.3px;
  height: 33.3px;
  top: 53%;
}
@media only screen and (max-width: 767px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-button-next {
  right: -2px;
}

.swiper-button-prev {
  left: -2px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  width: 33.3px;
  height: 33.3px;
  background-color: #fff;
  color: #191820;
  border: 1.5px solid #e5e5e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next:after {
  content: "\f105";
}

.swiper-button-prev:after {
  content: "\f104";
}

.swiper-pagination-bullets {
  position: relative;
  margin-top: 13px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  justify-items: center;
  padding-left: 15px;
}
.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 11px 0 5px;
  border: 1px solid var(--primary-color5);
  background: transparent;
  width: 8px;
  height: 8px;
  position: relative;
  opacity: 1;
}
.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background: var(--primary-color5);
}
.swiper-pagination-bullets .swiper-pagination-bullet-active::before {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid var(--primary-color5);
  overflow: visible;
}

.tf-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 1440px;
  max-width: 100%;
}

.mb63 {
  margin-bottom: 63px;
}

.mb44 {
  margin-bottom: 44px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb30 {
  margin-bottom: 40px;
}

.mb28 {
  margin-bottom: 28px;
}

.mb26 {
  margin-bottom: 26px;
}

.mb23 {
  margin-bottom: 23px;
}

.mb22 {
  margin-bottom: 22px;
}

.mt17 {
  margin-top: 17px;
}

.mt12 {
  margin-top: 12px;
}

.mt10 {
  margin-top: 10px;
}

.mt8 {
  margin-top: 8px;
}

.mt6 {
  margin-top: 6px;
}

.pt10 {
  padding-top: 10px;
}

.visible {
  overflow: visible !important;
}

a {
  text-decoration: none;
  color: var(--primary-color5);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover,
a:focus {
  color: var(--primary-color3);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@keyframes rainbow {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

:root {
  --golden-primary: linear-gradient(
    90deg,
    #b9b9b9 0%,
    rgba(255, 255, 255, 0.84375) 13.54%,
    #b9b9b9 28.13%,
    #ffffff 38.54%,
    #b0b0b0 53.13%,
    rgba(255, 255, 255, 0.99) 62.5%,
    rgba(167, 167, 167, 0.99) 76.04%,
    rgba(255, 255, 255, 0.99) 86.46%,
    rgba(187, 187, 187, 0.99) 97.4%
  );
  --golden-border: #dc862e;
  --golden-tab-card: #d88620;
  --golden-desc: #c7c7c7;
  --golden-card: #171717;
  --golden-btn: linear-gradient(
    90.85deg,
    #dc9834 0.85%,
    #bf7b0c 53.74%,
    #d38504 99.44%
  );
  --golden-text: #db8b05;

  --background-body: #fff;
  --primary-color: #565660;
  --primary-color2: rgba(86, 86, 96, 0.6);
  --primary-color3: #ff008e;
  --primary-color4: #e5e5e5;
  --primary-color5: #191820;
  --primary-color6: #fff;
  --primary-color7: #565660;
  --primary-color8: #565660;
  --primary-color9: #f7f7f7;
  --primary-color10: #b9b8bb;
  --primary-color11: #f7f7f7;
  --primary-color12: rgba(229, 229, 229, 0.5);
  --primary-color13: rgba(55, 73, 233, 0.1);
  --primary-color14: #ff008e;
  --primary-color15: #e5e5e5;
  --primary-color16: #191820;
  --primary-color17: #e5e5e5;
  --primary-color18: #565660;
  --primary-color19: #565660;
  --primary-color20: #191820;
  --primary-color21: #e5e5e5;
  --primary-color22: #f7f7f7;
  --primary-color23: rgba(86, 86, 96, 0.8);
  --primary-color24: rgba(55, 73, 233, 0.1);
  --primary-color25: rgba(55, 73, 233, 0.1);
  --primary-color26: #48bc65;
  --primary-color27: #fff;
  --primary-color28: #b9b9bf;
  --primary-color29: #f7f7f7;
  --primary-color30: #565660;
  --primary-color31: #b9b8bb;
  --primary-color32: #ccd2e3;
  --primary-color33: #ff008e;
  --primary-color34: #b9b8bb;
  --primary-color35: rgba(55, 73, 233, 0.1);
  --primary-color36: #e5e5e5;
  --primary-color37: #191820;
  --primary-color38: #e5e5e5;
  --primary-color39: #f7f7f7;
  --primary-color40: rgba(55, 73, 233, 0.1);
  --primary-color41: #f7f7f7;
  --primary-color42: #cfdbd5;
  --primary-color43: #ff008e;
  --primary-color44: #fff;
  --primary-color45: rgba(55, 73, 233, 0.1);
  --primary-color46: rgba(86, 86, 96, 0.1);
  --primary-color47: #e5e5e5;
  --primary-color48: #ff008e;
  --primary-color49: #bbbac2;
  --primary-color50: linear-gradient(
    141.18deg,
    rgba(0, 0, 0, 0) -1.4%,
    rgba(0, 0, 0, 0.0568) -1.38%,
    rgba(0, 0, 0, 0.0096) 113.47%
  );
  --primary-color51: #b9b8bb;
  --primary-color52: transparent;
  --product-color1: #e33b3b;
  --product-color2: #e5e5e5;
  --product-color3: #b9b8bb;
  --product-color4: #fff;
  --product-color5: #b9b8bb;
  --product-color6: #e5e5e5;
  --product-color7: #fff;
  --product-color8: #fff;
  --product-color9: rgba(86, 86, 96, 0.8);
  --product-color10: transparent;
  --product-color11: #e5e5e5;
  --product-color12: #b9b9bf;
  --product-color13: #fff;
  --product-color14: #fff;
  --product-color15: #e5e5e5;
  --product-color16: transparent;
  --product-color17: transparent;
  --product-color18: #e5e5e5;
  --product-color19: rgba(55, 73, 233, 0.1);
}

.is_dark {
  --background-body: #101010;
  --primary-color: rgb(247, 247, 247, 0.8);
  --primary-color2: #8a8aa0;
  --primary-color3: #ff008e;
  --primary-color4: #28303f;
  --primary-color5: #fff;
  --primary-color6: #1f1f2c;
  --primary-color7: #fff;
  --primary-color8: #b9b8bb;
  --primary-color9: rgba(86, 86, 96, 0.1);
  --primary-color10: #565660;
  --primary-color11: #28303f;
  --primary-color12: rgba(229, 229, 229, 0.5);
  --primary-color13: rgba(55, 73, 233, 0.2);
  --primary-color14: #fff;
  --primary-color15: #1f1f2c;
  --primary-color16: #f7f7f7;
  --primary-color17: rgba(40, 48, 63, 0.6);
  --primary-color18: #f7f7f7;
  --primary-color19: #ebebeb;
  --primary-color20: #ebebeb;
  --primary-color21: #d88720;
  --primary-color22: #1f1f2c;
  --primary-color23: #ebebeb;
  --primary-color24: rgba(0, 0, 0, 0);
  --primary-color25: #ff008e;
  --primary-color26: #ff008e;
  --primary-color27: #2b3f6c;
  --primary-color28: #fff;
  --primary-color29: rgba(255, 255, 255, 0.3);
  --primary-color30: #a9a9c2;
  --primary-color31: #f7f7f7;
  --primary-color32: #565660;
  --primary-color33: #1f1f2c;
  --primary-color34: #28303f;
  --primary-color35: #1f1f2c;
  --primary-color36: #565660;
  --primary-color37: #b9b8bb;
  --primary-color38: #ff008e;
  --primary-color39: rgba(255, 255, 255, 0.1);
  --primary-color40: rgba(255, 255, 255, 0.1);
  --primary-color41: transparent;
  --primary-color42: #cfdbd5;
  --primary-color43: #565660;
  --primary-color44: rgba(255, 255, 255, 0.1);
  --primary-color45: rgba(235, 240, 240, 0.1);
  --primary-color46: #565660;
  --primary-color47: rgba(86, 86, 96, 0.2);
  --primary-color48: #555abe;
  --primary-color49: #b9b8bb;
  --primary-color50: linear-gradient(
    141.18deg,
    rgba(0, 0, 0, 0) -1.4%,
    rgba(0, 0, 0, 0.0568) -1.38%,
    rgba(0, 0, 0, 0.0096) 113.47%
  );
  --primary-color51: #a9a9c2;
  --primary-color52: #ff008e;
  --product-color2: #b9b8bb;
  --product-color4: linear-gradient(
    156.67deg,
    rgba(112, 112, 114, 0.4) 0.07%,
    rgba(100, 97, 113, 0.148) 99.07%
  );
  --product-color5: #fff;
  --product-color6: #fff;
  --product-color8: #1f1f2c;
  --product-color9: rgba(255, 255, 255, 0.1);
  --product-color10: rgba(255, 255, 255, 0.1);
  --product-color11: #56566046;
  --product-color12: #b9b8bb;
  --product-color13: #292831;
  --product-color14: #565660;
  --product-color15: transparent;
  --product-color16: #ff008e;
  --product-color17: #1f1f2c;
  --product-color18: rgba(207, 219, 213, 0.15);
  --product-color19: rgba(255, 255, 255, 0.1);
}

#page {
  overflow: hidden;
}

header {
  border-bottom: 1px solid var(--primary-color4);
  padding-right: 0 !important;
}
header #site-header-inner {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
  margin-top: 15px;
}
header #site-header-inner #site-logo {
  flex-shrink: 0;
  margin-right: 14.7%;
}
header #site-header-inner #main-nav {
  flex-shrink: 1;
}
header #site-header-inner .header-right {
  margin-left: auto;
  display: inline-flex;
}
header #site-header-inner .header-right .tf-button,
header #site-header-inner .header-right .user,
header #site-header-inner .header-right .mode-switch {
  margin-right: 8px;
}
header #site-header-inner .header-right .user,
header #site-header-inner .header-right .mode-switch {
  width: 48px;
  height: 48px;
  line-height: 46px;
  text-align: center;
  background-color: var(--primary-color6);
  border: 1px solid var(--primary-color4);
  border-radius: 50%;
  display: inline-block;
}
header #site-header-inner .header-right .user {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
header #site-header-inner .header-right .user svg path {
  fill: var(--primary-color8);
}
header #site-header-inner .header-right .mode-switch {
  margin-right: 0;
}
header #site-header-inner .header-right .tf-button {
  padding: 11px 35px 11px 37px;
  font-weight: 700;
  font-size: 16px;
}
header.absolute {
  position: absolute;
  width: 100%;
  border-bottom: none;
  z-index: 100;
}

#main-nav ul {
  margin: 0px;
}
#main-nav ul li {
  position: relative;
  list-style: none;
}

#main-nav > ul > li {
  float: left;
  padding: 31px 30px 31px 13px;
}
#main-nav > ul > li > a {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  display: block;
  color: var(--primary-color5);
  padding-right: 15.5px;
}
#main-nav > ul > li > a:hover {
  color: var(--primary-color3);
}
#main-nav > .menu > li.menu-item-has-children > a::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

#main-nav .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: var(--primary-color6);
  border: 1px solid var(--primary-color15);
  border-radius: 8px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}
#main-nav .sub-menu .sub-menu {
  left: 105%;
  top: 0%;
  opacity: 0 !important;
  visibility: hidden;
}
#main-nav .sub-menu .sub-menu::before {
  display: none;
}
#main-nav .sub-menu li:not(:last-child) a {
  border-bottom: 1px solid var(--primary-color4);
}
#main-nav .sub-menu li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-color5);
  padding: 10px 15px;
}
#main-nav .right-sub-menu {
  left: auto;
  right: 0;
}

#main-nav > ul > .current-menu-item .current-menu-item > a,
#main-nav > ul > .current-menu-item > a {
  color: var(--primary-color3);
}

#main-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

#main-nav li .sub-menu li:hover .sub-menu {
  opacity: 1 !important;
  visibility: visible;
}

#main-nav .sub-menu li.current-item a,
#main-nav .sub-menu li a:hover {
  color: var(--primary-color3);
}

#main-nav > ul > li .sub-menu li {
  position: relative;
}
#main-nav > ul > li .sub-menu li a {
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}
#main-nav > ul > li .sub-menu li a:hover {
  padding-left: 35px;
}
#main-nav > ul > li .sub-menu li a:hover::after {
  width: 12px;
}
#main-nav > ul > li .sub-menu li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--primary-color3);
  left: 15px;
  top: 50%;
  transform: translate(0%, -50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#main-nav > ul > li .sub-menu li.current-item a {
  padding-left: 35px;
}
#main-nav > ul > li .sub-menu li.current-item a::after {
  width: 12px;
}

#main-nav-mobi {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999;
  background-color: var(--primary-color6);
}
#main-nav-mobi ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
#main-nav-mobi ul li {
  margin: 0;
  text-align: left;
  cursor: pointer;
  padding: 0;
  border-top: 1px solid var(--primary-color4);
  position: relative;
}
#main-nav-mobi ul li:first-child {
  border-top: 0px;
}

#main-nav-mobi ul > li > a {
  color: var(--primary-color5);
  display: inline-block;
  font-size: 16px;
  line-height: 48px;
  padding: 0 15px;
  font-weight: 500;
}
#main-nav-mobi ul > li.current-menu-item > a {
  color: var(--primary-color3);
}
#main-nav-mobi .sub-menu .menu-item a {
  margin-left: 15px;
}

#main-nav-mobi ul li > ul > li:first-child {
  border-top: 1px solid rgba(138, 138, 160, 0.3);
}

#main-nav-mobi .current-item > a,
#main-nav-mobi ul > li > a:hover {
  color: var(--primary-color3);
}

#main-nav-mobi .menu-item-has-children .arrow {
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-family: "FontAwesome";
  line-height: 48px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 0;
  width: 48px;
}
#main-nav-mobi .menu-item-has-children .arrow::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  color: var(--primary-color2);
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
  color: var(--primary-color3);
}

#main-nav-mobi .menu-item-has-children.current-menu-item .arrow:before {
  background: var(--primary-color3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#main-nav-mobi .menu-item-has-children .arrow.active {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#main-nav-mobi .menu-item-has-children .arrow.active:before {
  content: "\f107";
}

#main-nav-mobi ul ul li {
  background-color: var(--primary-color6);
  border-top: 1px solid var(--primary-color4);
}

.mobile-button {
  display: none;
  position: absolute;
  width: 26px;
  height: 26px;
  float: right;
  top: 50%;
  right: 15px;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mobile-button::before,
.mobile-button::after,
.mobile-button span {
  background-color: var(--primary-color2);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.mobile-button::before,
.mobile-button::after {
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.mobile-button span {
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 50%;
  overflow: hidden;
  text-indent: 200%;
}
.mobile-button::before {
  -webkit-transform: translate3d(0, -7px, 0);
  -moz-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}
.mobile-button::after {
  -webkit-transform: translate3d(0, 7px, 0);
  -moz-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}
.mobile-button.active span {
  opacity: 0;
}
.mobile-button.active::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.mobile-button.active::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.tf-button-submit:hover {
  opacity: 0.9;
  color: #fff;
}

.is_dark .tf-button-submit:hover {
  color: #fff;
}

.header.is-fixed {
  position: fixed;
  top: -88px;
  left: 0;
  width: 100%;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  background: var(--background-body);
  border: none;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header.is-fixed.is-small {
  top: 0;
}

.popup-user {
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
.popup-user .avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.popup-user .avatar_popup {
  position: absolute;
  z-index: 100;
  right: 0;
  padding: 31px 20px;
  top: 100%;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--primary-color15);
  background: var(--primary-color6);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
  font-size: 16px;
  width: 311px;
}
.popup-user .avatar_popup.visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.popup-user .avatar_popup h6 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
  color: var(--primary-color5);
}
.popup-user .avatar_popup .title {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
  color: var(--primary-color5);
}
.popup-user .avatar_popup .price {
  margin-bottom: 10px;
}
.popup-user .avatar_popup .price .style {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #5142fc;
}
.popup-user .avatar_popup .code {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--primary-color4);
}
.popup-user .avatar_popup .code p {
  font-size: 14px;
  line-height: 22px;
}
.popup-user .avatar_popup .code svg path {
  fill: var(--product-color5);
}
.popup-user .avatar_popup .links li {
  padding: 8px 0;
}
.popup-user .avatar_popup .links li:first-child {
  padding-top: 0;
}
.popup-user .avatar_popup .links li:last-child {
  padding-bottom: 0;
}
.popup-user .avatar_popup .links a {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
}
.popup-user .avatar_popup .links a svg {
  margin-right: 13px;
}
.popup-user .avatar_popup .links a svg path {
  fill: var(--primary-color14);
}
.popup-user .avatar_popup .links a span {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
}

.tf-page-title {
  padding: 24px 0 0;
}
.tf-page-title.style-2 {
  padding: 24px 0 15px;
}
.tf-page-title .breadcrumbs {
  margin-bottom: 43px;
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
}
.tf-page-title .breadcrumbs li {
  font-weight: 700;
  display: inline-block;
  padding: 0 24px 0 0px;
  color: var(--primary-color3);
}
.tf-page-title .breadcrumbs li a {
  font-weight: 700;
  position: relative;
  color: var(--primary-color8);
  padding-right: 33px;
}
.tf-page-title .breadcrumbs li a::after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  color: #8a8aa0;
  right: 0;
}
.tf-page-title .page-title-heading {
  color: var(--primary-color5);
}
.tf-page-title.style-2 .breadcrumbs {
  margin-bottom: 0;
}

.themesflat-pagination ul {
  margin-left: 2px;
  position: relative;
}
.themesflat-pagination ul li {
  display: inline-block;
  margin-right: 6.8px;
}
.themesflat-pagination ul li .page-numbers {
  display: inline-block;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  color: #706c83;
  background: var(--primary-color10);
  text-align: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--primary-color6);
  line-height: 40px;
  object-fit: cover;
}
.themesflat-pagination ul li .page-numbers:hover,
.themesflat-pagination ul li .page-numbers.current {
  background-color: var(--primary-color3);
  color: #fff;
  border-color: var(--primary-color3);
}

#scroll-top {
  position: fixed;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  text-align: center;
  z-index: 1;
  right: 14px;
  bottom: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  overflow: hidden;
}
#scroll-top.show {
  right: 15px;
  opacity: 1;
  visibility: visible;
}
#scroll-top::before,
#scroll-top::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#scroll-top::before {
  z-index: -1;
  background-color: var(--primary-color3);
}
#scroll-top::after {
  content: "\f077";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}
#scroll-top:hover {
  transform: translateY(-7%);
}

.is_dark #scroll-top::before {
  z-index: -1;
  background-color: #fff;
}
.is_dark #scroll-top::after {
  color: #5142fc;
}

.footer {
  padding: 100px 0;
  background-color: var(--primary-color9);
}
.footer .widget {
  margin-top: 60px;
}
.footer .widget.widget-infor {
  padding-right: 30%;
  margin-top: 0;
}
.footer .widget.widget-infor .logo {
  margin-bottom: 20px;
}
.footer .widget.widget-infor .content {
  margin-bottom: 23px;
  color: var(--primary-color8);
}
.footer .widget.widget-infor .social-item {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin-bottom: 30px;
}
.footer .widget.widget-infor .social-item li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--primary-color11);
  border-radius: 8px;
  margin-right: 12px;
}
.footer .widget.widget-infor .social-item li:last-child {
  margin-right: 0;
}
.footer .widget.widget-infor .copy-right {
  color: var(--primary-color10);
  font-size: 14px;
  line-height: 22px;
}
.footer .widget.widget-menu {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.footer .widget.widget-menu .menu ul li {
  padding-bottom: 11px;
}
.footer .widget.widget-menu .menu ul li a {
  color: var(--primary-color8);
  font-size: 14px;
  line-height: 22px;
}
.footer .widget.widget-menu .menu ul li a:hover {
  color: var(--primary-color3);
}
.footer .widget.widget-menu .menu ul li:last-child {
  padding-bottom: 0;
}
.footer .widget.widget-menu .menu.menu-1 {
  margin-left: 15px;
}
.footer .widget.widget-menu .menu.menu-2 {
  margin-left: 61px;
}
.footer .widget.widget-menu .menu.menu-3 {
  margin-left: 60px;
}
.footer .widget.widget-menu .menu.menu-4 {
  margin-left: 63px;
}
.footer .widget.widget-subcribe {
  margin-left: 20px;
  margin-top: 62px;
}
.footer .widget.widget-subcribe .content {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color8);
  letter-spacing: -0.2px;
  padding-right: 5px;
}
.footer .widget.widget-subcribe #subscribe-form {
  position: relative;
}
.footer .widget.widget-subcribe #subscribe-form input {
  /* border: 1px solid var(--primary-color12); */
  border-radius: 43px;
  width: 100%;
  padding: 12px 46px 12px 20px;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color8);
}
.footer .widget.widget-subcribe #subscribe-form input::placeholder {
  font-size: 14px;
  color: var(--primary-color8);
}
.footer .widget.widget-subcribe #subscribe-form input:focus {
  border-color: none !important;
}
.footer .widget.widget-subcribe #subscribe-form .tf-button {
  right: 0;
  position: absolute;
  height: 100%;
  width: 46px;
  padding: 0;
  background-color: #ff008e;
  border: 1px solid #ff008e;
  color: #fff;
  border-radius: 0px 40px 40px 0px;
  background: linear-gradient(
    90.85deg,
    #dc9834 0.85%,
    #bf7b0c 53.74%,
    #d38504 99.44%
  ) !important;
  border-radius: 0px 3px 25px 0px !important;
}
.footer .widget .widget-title {
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-color5);
  margin-bottom: 20px;
}

.preload {
  overflow: hidden;
}

.preload-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999999999;
  display: block;
  overflow: hidden;
}

.preload-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  z-index: 100;
  margin: -200px 0 0 -200px;
  background: #ffffff;
  background: url("../images/goldennft.png") center center no-repeat;
  animation: preload 1s linear infinite alternate;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.tf-blog {
  padding: 28px 0 80px 0;
}
.tf-blog .tf-blog-item {
  box-sizing: border-box;
  padding: 24px 24px 32px 24px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
}
.tf-blog .tf-blog-item .image {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 28px;
}
.tf-blog .tf-blog-item .image img {
  width: 100%;
}
.tf-blog .tf-blog-item .title {
  margin-bottom: 20px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: left;
  justify-content: space-between;
}
.tf-blog .tf-blog-item .title .tf-button {
  padding: 8px 9px;
  border-radius: 20px;
  background: #ff008e;
  color: #fff;
}
.tf-blog .tf-blog-item .content {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px;
}
.tf-blog .tf-blog-item .meta > span {
  margin-right: 26px;
  font-size: 18px;
  line-height: 26px;
}
.tf-blog .tf-blog-item .meta > span i {
  margin-right: 7px;
  color: var(--primary-color3);
}
.tf-blog .tf-blog-item .meta .admin i {
  font-size: 15px;
  margin-left: 2px;
}
.tf-blog .tf-blog-item.is_dark {
  background: linear-gradient(
    156.67deg,
    rgba(112, 112, 114, 0.1) 0.07%,
    rgba(100, 97, 113, 0.037) 99.07%
  );
  background: #ffffff;
  border: 1px solid #e5e5e5;
}
.tf-blog .tf-blog-item.style-2 {
  padding: 24px 24px 30px 24px;
}
.tf-blog .tf-blog-item.style-2 .title {
  margin-bottom: 18px;
}
.tf-blog .tf-blog-item.style-2 .content {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}
.tf-blog .load-more {
  text-align: center;
  margin-top: 10px;
}
.tf-blog .load-more .tf-button {
  border-radius: 50px;
  font-size: 14px;
  padding: 10px 23px;
  background-color: var(--primary-color24);
  border: 1px solid var(--primary-color25);
  color: var(--primary-color14);
  font-weight: 700;
}
.tf-blog .load-more .tf-button:hover {
  background-color: #ff008e;
  border-color: #ff008e;
  color: #fff;
}
.tf-blog .load-more.style-2 {
  display: block;
  margin-top: 40px;
}
.tf-blog .main-content {
  margin-right: 50px;
}
.tf-blog .side-bar .widget {
  margin-bottom: 30px;
  padding: 24px;
  background-color: var(--primary-color6);
  border-radius: 16px;
  border: 1px solid var(--primary-color15);
}
.tf-blog .side-bar .widget .widget-title {
  padding: 16px;
  background-color: var(--primary-color13);
  color: var(--primary-color14);
  margin-bottom: 30px;
  border-radius: 12px;
}
.tf-blog .side-bar .widget:last-child {
  margin-bottom: 0;
}
.tf-blog .side-bar .widget.widget-category li {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid var(--primary-color17);
}
.tf-blog .side-bar .widget.widget-category li span {
  margin-left: auto;
  color: var(--primary-color16);
  font-size: 18px;
  line-height: 26px;
}
.tf-blog .side-bar .widget.widget-category li a {
  font-size: 18px;
  line-height: 26px;
  color: var(--primary-color16);
}
.tf-blog .side-bar .widget.widget-category li a:hover {
  color: var(--primary-color3);
}
.tf-blog .side-bar .widget.widget-category li:first-child {
  padding-top: 0;
}
.tf-blog .side-bar .widget.widget-category li:last-child {
  padding-bottom: 0;
  border: none;
}
.tf-blog .side-bar .widget.widget-recent-post li {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding-bottom: 19px;
  padding-top: 19px;
  border-bottom: 1px solid var(--primary-color17);
}
.tf-blog .side-bar .widget.widget-recent-post li:first-child {
  padding-top: 0;
}
.tf-blog .side-bar .widget.widget-recent-post li:last-child {
  padding-bottom: 0;
  border: none;
}
.tf-blog .side-bar .widget.widget-recent-post li .post-img {
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 16px;
}
.tf-blog .side-bar .widget.widget-recent-post li .post-img img {
  border-radius: 8px;
}
.tf-blog .side-bar .widget.widget-recent-post li .post-content .title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 8px;
}
.tf-blog .side-bar .widget.widget-recent-post li .post-content .title a {
  color: var(--primary-color16);
}
.tf-blog .side-bar .widget.widget-recent-post li .post-content .title a:hover {
  color: var(--primary-color3);
}
.tf-blog .side-bar .widget.widget-recent-post li .post-content .post-category {
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color8);
}
.tf-blog .side-bar .widget.widget-tag .widget-title {
  margin-bottom: 28px;
}
.tf-blog .side-bar .widget.widget-tag ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin-right: -8px;
}
.tf-blog .side-bar .widget.widget-tag ul li {
  margin-right: 8px;
  margin-bottom: 11px;
}
.tf-blog .side-bar .widget.widget-tag ul li a {
  padding: 0 11px;
  border-radius: 20px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color7);
  border: 1px solid var(--primary-color4);
  line-height: 35px;
  font-weight: 700;
}
.tf-blog .side-bar .widget.widget-tag ul li a:hover {
  background-color: var(--primary-color3);
  color: #fff;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(2) a {
  padding: 0 20px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(3) a {
  padding: 0 20px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(4) a {
  padding: 0 9px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(5) a {
  padding: 0 19px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(6) a {
  padding: 0 10px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(7) a {
  padding: 0 20px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(8) a {
  padding: 0 20px;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(9) {
  margin-right: 0;
}
.tf-blog .side-bar .widget.widget-tag ul li:nth-child(9) a {
  padding: 0 14px;
}

.is_dark .tf-blog-item {
  background: linear-gradient(
    156.67deg,
    rgba(112, 112, 114, 0.1) 0.07%,
    rgba(100, 97, 113, 0.037) 99.07%
  );
  border: 1px solid transparent;
}

.tf-blog-detail {
  padding: 28px 0 102px 0;
}

.detail-inner .content-top {
  text-align: center;
  margin-bottom: 24px;
}
.detail-inner .content-top .title {
  margin-bottom: 24px;
  color: var(--primary-color5);
  padding: 0 40px;
}
.detail-inner .content-top .author-infor {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.detail-inner .content-top .author-infor .image {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
  margin-right: 12px;
}
.detail-inner .content-top .author-infor span,
.detail-inner .content-top .author-infor a {
  margin-right: 5px;
}
.detail-inner .content-top .author-infor a {
  margin-right: 9px;
}
.detail-inner .content-top .author-infor .mr9 {
  margin-right: 9px;
}
.detail-inner .image {
  margin-bottom: 32px;
}
.detail-inner .image.style-2 {
  margin-right: -20px;
}
.detail-inner .image.style-2 img.mr20 {
  margin-right: 17px;
}
.detail-inner .content-inner {
  margin-bottom: 32px;
}
.detail-inner .content-inner .title {
  margin-bottom: 16px;
  color: var(--primary-color5);
}
.detail-inner .content-inner p {
  color: var(--primary-color18);
  font-size: 18px;
  line-height: 26px;
}
.detail-inner .content-bottom {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 38px;
  margin-top: -7px;
  border-bottom: 1px solid var(--primary-color21);
  margin-bottom: 40px;
}
.detail-inner .content-bottom .widget {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.detail-inner .content-bottom .widget .widget-title {
  font-size: 18px;
  line-height: 26px;
  margin-right: 14px;
  color: var(--primary-color5);
}
.detail-inner .content-bottom .widget ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.detail-inner .content-bottom .widget ul li {
  margin-right: 11px;
}
.detail-inner .content-bottom .widget ul li a {
  color: var(--primary-color19);
}
.detail-inner .content-bottom .widget ul li a:hover {
  color: var(--primary-color3);
}
.detail-inner .content-bottom .widget.widget-socical ul li:nth-child(2) a {
  font-size: 12px;
}
.detail-inner .content-bottom .widget.widget-tag ul li {
  margin-right: 0px;
}
.detail-inner .content-bottom .widget.widget-tag ul li a {
  margin-right: 4px;
  color: var(--primary-color20);
}
.detail-inner .content-bottom .widget.widget-tag ul li a:hover {
  color: var(--primary-color3);
}
.detail-inner .content-bottom .widget.widget-tag ul li:last-child a {
  margin-right: 0;
}
.detail-inner #comments .heading {
  margin-bottom: 24px;
}
.detail-inner #comments .comment-form fieldset {
  float: left;
  width: 50%;
  margin-bottom: 20px;
}
.detail-inner #comments .comment-form fieldset.name {
  padding-right: 10px;
}
.detail-inner #comments .comment-form fieldset.email {
  padding-left: 10px;
}
.detail-inner #comments .comment-form fieldset.message {
  width: 100%;
  margin-bottom: 23px;
}
.detail-inner #comments .comment-form .tf-button {
  border-radius: 50px;
  background-color: #ff008e;
  color: #fff;
  border: none;
  padding: 12px 26px;
}

.detail-wrap {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.detail-wrap .detail-inner {
  width: 74.47%;
  padding-right: 60px;
}
.detail-wrap .detail-inner .content-top {
  text-align: left;
  margin-bottom: 30px;
}
.detail-wrap .detail-inner .content-top .title {
  padding: 0 0 23px 0;
  margin-bottom: 17px;
  border-bottom: 1px solid var(--primary-color21);
}
.detail-wrap .detail-inner .content-top .meta-blog {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.detail-wrap .detail-inner .content-top .meta-blog .meta.meta-right {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.detail-wrap
  .detail-inner
  .content-top
  .meta-blog
  .meta.meta-right
  .meta-inner:first-child {
  padding-right: 24px;
  border-right: 1px solid var(--primary-color3);
}
.detail-wrap
  .detail-inner
  .content-top
  .meta-blog
  .meta.meta-right
  .meta-inner:last-child {
  padding-left: 23px;
}
.detail-wrap .detail-inner .content-top .meta-blog .meta h6 {
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color20);
}
.detail-wrap .detail-inner .content-top .meta-blog .meta p {
  color: var(--primary-color8);
  font-size: 12px;
  line-height: 20px;
}
.detail-wrap .detail-inner .content-bottom {
  margin-top: -6px;
  padding-bottom: 40px;
}
.detail-wrap .side-bar {
  width: 25.53%;
}
.detail-wrap .side-bar .widget {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 36px;
}
.detail-wrap .side-bar .widget .widget-title {
  background-color: transparent;
  padding: 0;
  color: var(--primary-color5);
  margin-bottom: 23px;
}
.detail-wrap .side-bar .widget.widget-recent-post li {
  align-items: center;
  background-color: var(--primary-color22);
  padding: 10px;
  border-bottom: none;
  margin-bottom: 12px;
  border-radius: 8px;
}
.detail-wrap .side-bar .widget.widget-recent-post li:first-child {
  padding-top: 8px;
}
.detail-wrap .side-bar .widget.widget-recent-post li:last-child {
  margin-bottom: 0;
  padding-bottom: 12px;
}
.detail-wrap .side-bar .widget.widget-recent-post li .post-img {
  flex-shrink: 0;
  margin-right: 13px;
}
.detail-wrap .side-bar .widget.widget-recent-post li .post-content {
  width: 86%;
}
.detail-wrap .side-bar .widget.widget-recent-post li .post-content .title {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}
.detail-wrap .side-bar .widget.widget-recent-post li .post-content .post-meta {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.detail-wrap
  .side-bar
  .widget.widget-recent-post
  li
  .post-content
  .post-meta
  span {
  font-size: 12px;
  line-height: 20px;
  color: var(--primary-color23);
}
.detail-wrap .side-bar .widget.widget-tag .widget-title {
  margin-bottom: 24px;
}
.detail-wrap .side-bar .widget.widget-tag ul li a {
  font-size: 14px;
  line-height: 34px;
  font-weight: 400;
  padding: 0 12px;
  background-color: var(--primary-color22);
  border: none;
}
.detail-wrap .side-bar .widget.widget-tag ul li:nth-child(2) a,
.detail-wrap .side-bar .widget.widget-tag ul li:nth-child(3) a {
  padding: 0 20px;
}
.detail-wrap .side-bar .widget.widget-tag ul li:nth-child(4) a {
  padding: 0 10px;
}
.detail-wrap .side-bar .widget.widget-tag ul li:nth-child(9) a {
  padding: 0 18px;
}
.detail-wrap .side-bar .widget.widget-tag ul li:nth-child(6) a {
  padding: 0 12px;
}
.menu-item.search-item {
  padding: 25px 30px 25px 13px !important;
}
.search-item input {
  padding: 5px 15px !important;
  border: 0px !important;
  font-size: 16px !important;
}
.search-item .search-form {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  width: 230px;
}
.search-item .icon-fl-search-filled {
  margin-right: 10px;
}
.tf-author .image img {
  border-radius: 50%;
}
.details-product .avatar img {
  border-radius: 50%;
}
.dropdown:hover ul {
  display: block !important;
}
.dropdown {
  z-index: auto;
}
.dropdown:hover {
  z-index: 1000;
}
.right-thumb .top-cl {
  max-height: 90px;
  min-height: 90px;
}
.right-thumb .bottom-cl {
  max-height: 90px;
  min-height: 90px;
}
.right-thumb img {
  max-width: 100%;
  min-width: 100%;
  min-height: 90px;
  max-height: 90px;
  object-fit: cover;
}
.tf-accordion .tf-toggle .tf-toggle-title.active + .tf-toggle-content {
  display: block !important;
  visibility: visible;
}
.tf-accordion .tf-toggle .tf-toggle-content {
  transition: all 0.5s ease;
  visibility: hidden;
  height: 134px;
}
.tf-accordion .tf-toggle .tf-toggle-title {
  cursor: pointer;
}
.avatar .user {
  width: 20px !important;
  height: 18px !important;
}
header .popup-user .avatar {
  background-color: var(--golden-card);
  border: 1px solid var(--primary-color4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tf-toggle-title.accordion-header button {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  padding: 17px 20px;
  font-family: "Inter";
}
.tf-toggle-title.accordion-header button.collapsed {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.tf-toggle-title.accordion-header {
  padding: 0px !important;
}
.accordion-item .accordion-body {
  padding: 16px 20px;
  border-left: 1px solid var(--primary-color15);
  border-right: 1px solid var(--primary-color15);
  border-bottom: 1px solid var(--primary-color15);
  border-radius: 0px 0px 12px 12px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #565660;
  background: var(--golden-card) !important ;
}
.is_light .accordion-button.collapsed {
  background: #fff !important;
  color: #000;
}
.is_light .accordion-button {
  background-color: #ff008e !important;
  color: #fff;
}
.is_dark .accordion-button.collapsed {
  background: #171717 !important;
  color: var(--golden-primary);
}
.is_dark .accordion-button {
  background-color: #dc9834 !important;
  color: #fff;
}
.is_dark .accordion-body {
  color: #fff;
}
.tf-accordion .tf-toggle .tf-toggle-title button.collapsed::after {
  content: "\f067";
}
.tf-accordion .tf-toggle .tf-toggle-title button::after {
  content: "\f068";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: var(--primary-color5);
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.tf-accordion .tf-toggle .tf-toggle-title::after {
  content: none !important;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: var(--primary-color5);
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.filter-menu li:hover a {
  color: #fff !important;
}
.content-tab .content-inner.active {
  opacity: 1;
  animation: fade 2s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
}
.tf-tab .menu-tab li {
  cursor: pointer;
}
.inner-content {
  opacity: 1;
  animation: fade 2s;
}
.is_light #logo_header {
  background: url("../images/goldennft.png") no-repeat scroll center;
  max-width: 100%;
  height: 52px;
  width: 200px;
  background-size: 100%;
}
.is_dark #logo_header {
  background: url("../images/goldennft.png") no-repeat scroll center;
  max-width: 100%;
  height: 70px;
  width: 200px;
  background-size: 100px 100%;
}
#site-header-inner #logo_header {
  margin-left: -31px;
}
@keyframes height {
  0% {
    height: 0;
  }
  100% {
    height: 284px;
  }
}
.homepage .header.fixedTop {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: -88px;
  transition: all 0.5s ease;
}
.header.fixedTop.scroll {
  top: 0px;
  background: #171717;
  padding-bottom: 20px;
}

.header.fixedTop {
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: -88px;
  transition: all 0.5s ease;
}
.header.fixedTop.scroll {
  top: 0px;
}
/* .is_dark .fixedTop{background:#1f1f2c !important;box-shadow: 1px 2px 17px 1px rgba(0,0,0,0.2);}
.is_light .fixedTop{background:#fff !important;box-shadow: 1px 2px 17px 1px rgba(0,0,0,0.2);} */
.popup-user:hover .avatar_popup {
  visibility: visible;
  opacity: 1;
}
.is_light p.icon-change {
  background: url("../images/sun.png") no-repeat scroll center;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.is_dark p.icon-change {
  background: url("../images/moon.png") no-repeat scroll center;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.mode-switch {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.tf-author .tick {
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  top: 31px;
  right: 0px;
}
.search-item input {
  padding: 8px 15px !important;
}
.thumb-collection .left-thumb img {
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}
.homepage .header {
  position: absolute;
  width: 100%;
  border-bottom: none;
  z-index: 100;
}
.arrow + ul.sub-menu {
  height: 0vh;
  overflow: hidden;
  transition: height 0.25s ease-out;
}
.arrow.active + ul.sub-menu {
  height: 200px;
  overflow: auto;
  transition: height 0.25s ease-in !important;
}
.mobile-button + #main-nav-mobi {
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: -webkit-transform 0.26s ease-out;
  -o-transition: -o-transform 0.26s ease;
  -ms-transition: -ms-transform 0.26s ease;
  transition: transform 0.26s ease;
}
.mobile-button.active + #main-nav-mobi {
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.mobile-button.active + #main-nav-mobi ul.menu {
  display: block !important;
}
.ani4 img {
  object-fit: cover;
  overflow: hidden;
}
.thumb-banner .thumb {
  border-radius: 50px;
}
.thumb-banner .thumb img {
  max-width: 100%;
  min-width: 100%;
  border: 6px solid #ff008e;
  max-height: 512px;
  min-height: 512px;
  border-radius: 43px;
  object-fit: cover;
}
.homepage .tf-banner-create .thumb-banner {
  padding-top: 0px;
}
.card-countdown .countdown_value span {
  font-weight: 700;
  font-size: 33px;
  line-height: 56px;
  color: #fff;
}
.menu-item a.active {
  color: #171717 !important;
}
.ani4 {
  text-align: center;
}
#main-nav-mobi {
  padding: 10px 0px 20px;
}
.top-banner .ani4 img {
  border: 6px solid #ff008e;
  border-radius: 50px;
  position: relative;
}
.top-banner .ani4 {
  position: relative;
}
.top-banner .ani4:before {
  content: "";
  background: url("../images/element-pink.png") no-repeat scroll center;
  background-size: cover;
  width: 88px;
  height: 177px;
  display: block;
  position: absolute;
  top: -70px;
  left: -40px;
  z-index: 999;
}
.top-banner .ani4:after {
  content: "";
  background: url("../images/element-blue.png") no-repeat scroll center;
  background-size: cover;
  width: 130px;
  height: 177px;
  display: block;
  position: absolute;
  top: 67px;
  right: -61px;
  z-index: 999;
}
.tf-slider-item.style-4.top-banner .image {
  padding-top: 80px;
}
.top-banner .ani4 img {
  max-width: 100%;
  min-width: 100%;
  max-height: 512px;
  min-height: 512px;
  object-fit: cover;
}
.top-banner .ani4 {
  max-width: 600px;
  min-width: 600px;
  max-height: 512px;
  min-height: 512px;
  object-fit: cover;
  margin: auto;
}

.search-form input::placeholder {
  font-size: 15px !important;
}
.thumb-banner .thumb {
  max-height: 512px;
  min-height: 512px;
}
.top-menu {
  transform: none !important;
}
.dropdown ul.show {
  top: 0px;
  opacity: 0 !important;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.15s ease-out;
}
.dropdown:hover ul.show {
  top: 40px;
  opacity: 1 !important;
  visibility: visible;
  transition: all 0.25s ease-in;
}
.btn-option .show {
  top: 0px;
  opacity: 0 !important;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.15s ease-out;
}
.btn-option:hover .show {
  top: 40px;
  opacity: 1 !important;
  visibility: visible;
  transition: all 0.25s ease-in;
}
.btn-option .show > a {
  display: block;
}
.thumb-pagetitle {
  width: 100%;
}
.featured-countdown span {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 16px;
  color: #191820;
}

.sc-product .features .featured-countdown {
  text-align: center;
  width: 220px;
}

.tf-page-title .thumb-pagetitle img {
  width: 100%;
}
.ethdropdown ul {
  z-index: -999;
  top: 0px !important;
  transform: translateY(-20px) !important;
}
.ethdropdown:hover ul {
  opacity: 1;
  transform: translateY(40px) !important;
}
.collection-over .owl-carousel .owl-stage {
  max-height: 350px !important;
  min-height: 350px !important;
  padding-top: 20px;
}
.company #logo_header {
  margin-bottom: 0px;
}
.header .menu-sub button {
  padding: 0px 15px !important;
  background: transparent !important;
  font-weight: 500;
  width: 100%;
  text-align: left;
  position: relative;
}
.header .menu-sub button.collapsed:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: var(--primary-color5);
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.header .menu-sub button:after {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: var(--primary-color5);
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.menu-sub .accordion-item .accordion-body {
  border: 0px solid !important;
  padding: 0px;
}
.menu-sub .menu-item a {
  font-size: 12px !important;
}
.menu-sub.accordion h2 {
  font-size: 44px;
  line-height: 0.25;
  padding-bottom: 15px !important;
}
#main-nav-mobi {
  overflow: auto;
}
.show li:hover span {
  color: #fff !important;
  transition: all 0.5s ease;
}
.tf-item-detail-inner .image {
  max-height: 564px;
  min-height: 564px;
}
.tf-item-detail-inner .image img {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
}
.modal .modal-dialog {
  max-width: 690px;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: auto !important;
}
.btn-close {
  background: transparent !important;
}
.btn-close:after {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: var(--golden-text);
  font-size: 20px;
}
.wishlish:hover .option_popup {
  opacity: 1 !important;
  transform: translateY(10px);
}
.wishlish .option_popup a {
  display: block !important;
}
.wishlish .option_popup a:nth-child(n) {
  content: "";
  padding-bottom: 10px;
}
.wishlish .option_popup a:last-child {
  content: none;
  padding-bottom: 0px;
}
.wishlish .option_popup {
  transform: translateY(-10px);
  transition: all 0.25s ease;
}

.is_dark #scroll-top::after {
  color: #ffffff !important;
  background: #d68520;
}
.tf-slider-item.style-4 .content-inner form .search-form input {
  width: 93%;
}
.list_for_sale_now .icon {
  width: 72px;
  height: 48px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(55, 73, 233, 0.1);
  border-radius: 10px;
  margin-right: 20px;
}
.list_for_sale_now .list_border {
  width: calc(50% - 30px);
  margin-right: 0;
  display: inline-block;
  border: 1px solid #dc862e;
  color: var(--primary-color5);
  border-radius: 10px;
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.list_for_sale_now .list_border.active {
  border: 1px solid var(--primary-color33);
  background-color: var(--product-color8);
}
.list_for_sale_now .list_border.active .icon i {
  color: var(--primary-color33);
}
.is_dark .list_for_sale_now .list_border .icon {
  background: rgba(255, 255, 255, 0.1);
}
.is_dark .list_for_sale_now .list_border.active .icon i {
  color: #fff;
}
.add-nft-inner .create-button {
  margin-left: 0px !important;
}
.add-nft-inner .drag-upload {
  padding: 94px 20px 80px !important;
}
.react-select__control {
  border-top-color: transparent !important;
  border-left-color: transparent !important
  ;
  border-right-color: transparent !important;
  border-bottom-color: var(--primary-color21) !important;
  border-radius: 0px !important;
}
.set-item1 input.set-items {
  border-top-color: transparent !important;
  border-left-color: transparent !important
    ;
  border-right-color: transparent !important;
  border-bottom-color: var(--golden-border);
  border-radius: 0px !important;
  padding: 13px 21px !important;
}
.react-select__indicator-separator {
  display: none !important;
}
.react-select__control--is-focused {
  outline: none !important;
  transform: none !important;
  box-shadow: 0px 0px !important;
  border-color: transparent !important;
  border-bottom: 1px solid !important;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  border-radius: 0px !important;
  min-height: 30px !important;
}
.react-select__menu-list {
  font-size: 13px !important;
  background: transparent !important;
  z-index: 999 !important;
}
.react-select__menu-list:hover {
  font-size: 13px !important;
  background: transparent !important;
}
/* .react-select__menu{top:28px !important;z-index:99999 !important;} */
.react-select__option:hover,
.react-select__menu:hover {
  background: transparent !important;
}
.react-select__option:hover {
  color: #ff008e !important;
  transition: all 0.5s ease;
  cursor: pointer;
}
.react-select__option {
  background: transparent !important;
  z-index: 999 !important;
}
.is_dark .react-select__control {
  background: transparent !important;
  border-bottom-color: var(--golden-border) !important;
}
.is_light .react-select__control {
  background: transparent !important;
  /* border-bottom-color: var(--primary-color21) !important;  */
  border-bottom-color: #dc862e !important;
}
.w-80 {
  width: 80%;
}
.is_dark .react-select__menu {
  background: #1c1c1c !important;
}
.is_light .react-select__menu {
  background: #fff !important;
}
.set-item1 .starting_date {
  width: 100%;
  margin-right: 10px;
}
.set-item1 .ending_date {
  width: 100%;
  margin-left: 10px;
}
.rdtDays table {
  border: 0px;
}
.rdtDays th,
.rdtDays td {
  border: 0px solid !important;
}
.rdt_date_time .modal-header {
  border: 0px solid transparent !important;
}
.is_dark .rdtStatic .rdtPicker {
  background: transparent !important;
  border: 0px solid !important;
}
.rdtPicker table {
  table-layout: auto;
}
.is_dark .rdtPicker td.rdtDay:hover,
.is_dark .rdtPicker td.rdtHour:hover,
.is_dark .rdtPicker td.rdtMinute:hover,
.is_dark .rdtPicker td.rdtSecond:hover,
.is_dark .rdtPicker .rdtTimeToggle:hover {
  background: #dc9834 !important;
  color: #fff !important;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #dc862e !important;
}
.is_dark .rdtCounter .rdtBtn:hover {
  background: #ff008e !important;
}
.rdtPicker table {
  border: 0px solid !important;
}
.rdtTime td {
  border: 0px solid !important;
}
.rdtPicker td.rdtActive {
  background: #ff008e !important;
}
.is_light .react-select__option {
  color: #fff !important;
}
.is_dark .react-select__option {
  color: #fff !important;
}
.react-select__menu-list {
  z-index: 9999 !important;
}
.react-select__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 2px !important;
  margin-bottom: 0px !important;
}
.react-select_option {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 2px !important;
  margin-bottom: 0px !important;
}
/* .react-select__menu{padding-top:5px;padding-bottom:5px;margin-top:2px;margin-bottom:2px;} */
.features .product-media {
  min-height: 390px;
  max-height: 390px;
  max-width: 100%;
  min-width: 100%;
  margin: auto;
}
.features .product-media img {
  min-height: 390px;
  max-height: 390px;
  object-fit: cover;
}
.create-item .img img {
  width: 70px;
  height: 48px;
  object-fit: cover;
}
.list_for_sale_now .set-item1 {
  position: relative;
}
/* .list_for_sale_now .set-item1 .react-select__menu{position:absolute !important;top:100px !important;
z-index:999 !important} */
.tf-item-detail-inner .content {
  width: 100%;
}
.avatar .edit_profile {
  background: var(--golden-tab-card);
  position: absolute;
  top: 16px;
  width: 30px;
  left: 0;
  right: 0;
  border-radius: 50%;
  height: 30px;
  margin: auto;
  box-shadow: 1px 2px 12px -2px #000;
}
.avatar .edit_profile i {
  position: absolute;
  color: #fff !important;
  font-size: 12px;
  left: 9px;
  top: 8px;
}
.avatar .edit_profile input {
  opacity: 0;
  z-index: 99999999;
  position: relative;
  cursor: pointer;
}
.avatar .edit_profile:hover {
  background: #000;
  transition: background 0.5s ease-in-out;
}

.thumb-pagetitle .edit_profile {
  background: var(--golden-tab-card);
  position: absolute;
  bottom: 16px;
  width: 30px;
  right: 10px;
  border-radius: 50%;
  height: 30px;
  margin: auto;
  box-shadow: 1px 2px 12px -2px #000;
}
.thumb-pagetitle .edit_profile i {
  position: absolute;
  color: #fff !important;
  font-size: 12px;
  left: 9px;
  top: 8px;
}
.thumb-pagetitle .edit_profile input {
  opacity: 0;
  z-index: 99999999;
  position: relative;
  cursor: pointer;
}
.thumb-pagetitle .edit_profile:hover {
  background: #000;
  transition: all 0.5s ease-in-out;
}
.react-select__menu {
  position: absolute !important;
  z-index: 99999 !important;
}
.set-item1 .react-select__menu-list:hover {
  color: #ff008e !important;
  transition: all 0.5s ease;
}
.set-item1 .react-select__option:hover {
  color: #ff008e !important;
  transition: all 0.5s ease;
}
.set-item1 .react-select__menu:hover {
  color: #ff008e !important;
  transition: all 0.5s ease;
}
.author img {
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
  object-fit: cover !important;
}
.infor img {
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
  object-fit: cover !important;
}
.box-bid .image-bid img {
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
}
.react-share__ShareButton {
  border-radius: 0px;
  margin-top: 20px;
}
.number-wishlish .active {
  color: #e33b38;
}

.product-media video {
  max-height: 288px !important;
  min-height: 288px !important;
  object-fit: cover !important;
}

.react-share__ShareButton:hover i {
  color: var(--golden-text) !important;
  transition: all 0.5s ease;
}
.react-share__ShareButton:hover p {
  color: var(--golden-text) !important;
  transition: all 0.5s ease;
}
.tf-author .image img.auttor {
  width: 46px !important;
  height: 46px !important;
  border-radius: 50% !important;
}
.dashboard-content .inner-content.follow .content-follow .card-author .name {
  margin-bottom: 40px !important;
}
.col-rankingg.dot {
  display: none !important;
}
.dashboard-content
  .inner-content.follow
  .content-follow
  .card-author
  .avatar
  img {
  object-fit: cover;
}
.add-nft-inner .create-button li {
  margin-left: 0px !important;
}
.add-nft-inner .create-button {
  justify-content: space-between !important;
}
.table-ranking .title-ranking .col-rankingg {
  width: 150px !important;
  text-align: center !important;
}
.table-ranking .content-ranking .col-rankingg {
  width: 150px !important;
  text-align: center !important;
}
/* .table-ranking .content-ranking .col-rankingg .image{margin:auto !important;} */
.banner-collection-inner .img-bg {
  max-height: 250px !important;
  min-height: 250px !important;
}
.banner-collection-inner img.img-bg {
  max-width: 100% !important;
  min-width: 100% !important;
  object-fit: cover !important;
}
.putonsalede {
  color: #000;
}
.approvetransaction .item_imgs img {
  margin-top: 10px;
}
.fixedpricedropdownflex {
  margin-bottom: 10px;
}
.board .thumb-pagetitle {
  max-height: 360px;
  min-height: 360px;
}
.board .thumb-pagetitle img {
  max-width: 100% !important;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
}
.privacy_policy p {
  margin-bottom: 20px;
  justify-content: space-between;
}

.approvetransaction .item_imgs video {
  max-height: 200px;
  min-height: 200px;
  max-width: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.putonsale .item_imgs {
  max-height: 300px;
  min-height: 300px;
}
.error_msg {
  color: #f00 !important;
  position: relative;
  top: 5px;
}
.required {
  color: #d3892b !important;
}
.error_msg {
  color: #f00 !important;
}
/* .footer .widget.widget-subcribe #subscribe-form input{border:1px solid #565660 !important} */
.footer .widget.widget-infor .copy-right {
  color: #565660 !important;
}
/* body .table-ranking .title-ranking .col-rankingg.blockchain {
    width: 145px !important;
} */
body .table-ranking .content-ranking .col-rankingg,
body .table-ranking .title-ranking .col-rankingg {
  text-align: left !important;
}
.col-rankingg .image video {
  max-height: 60px;
  min-height: 60px;
  height: 60px;
  object-fit: cover;
  max-width: 100px;
  min-width: 100px;
}
.search-button a {
  display: inline-block;
  background: rgba(55, 73, 233, 0.1);
  border-radius: 50px;
  padding: 8px 23px;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 14px;
  color: var(--primary-color14);
  border: 2px solid var(--product-color16);
}

.search-model {
  background: #fff !important;
  position: absolute;
  width: 100%;
  border-radius: 10px;
  z-index: 99999;
  box-shadow: 1px 1px 12px -2px #000;
  top: 80px;
}
.search-item {
  position: relative;
}
.search-model .content {
  padding: 10px 20px 0px 10px;
}
.search-model .content span {
  font-size: 13px !important;
}
.is_dark .search-model {
  background: var(--golden-card) !important;
}
.search-model .contentTop {
  height: 210px;
  overflow: auto;
  padding: 10px;
}
body .product-media video {
  max-height: 288px !important;
  min-height: 288px !important;
  object-fit: cover !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.filter-menu li a {
  text-transform: capitalize;
}
/* .putonsale .item_imgs {
  max-height: 250px !important;
  min-height: 250px !important;
} */
.approvetransaction .item_imgs video {
  margin-top: 10px;
}
.banner-collection-inner {
  max-height: 250px;
  min-height: 250px;
}
.search-model .content img {
  width: 35px;
  height: 35px;
}
.banner-collection-inner .img-banner {
  width: 92px;
  height: 92px;
  object-fit: cover;
}
.tf-item-detail-inner object {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.product-media object {
  max-width: 288px;
  min-width: 288px;
  max-height: 288px;
  min-height: 288px;
  object-fit: cover;
}
.product-media object img {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 288px;
}
.product-media object img {
  max-width: 100%;
  min-width: 100%;
  max-height: 288px;
  min-height: 288px;
  object-fit: cover;
  height: 288px;
}
.create-collection li.active {
  border: 1px solid var(--primary-color3) !important;
}
.footer .widget.widget-menu .menu ul li a {
  text-transform: capitalize;
}
.header .tf-container {
  position: initial;
}
.header {
  position: relative;
}
.add-nft-inner fieldset input {
  color: #fff !important;
}
/* .footer .widget.widget-menu .menu{margin-left:30px !important} */
#main-nav > ul > li .sub-menu li a {
  text-transform: capitalize;
}
.filter-menuu .dashboard img {
  width: 20px;
  height: 20px;
}
.product-media iframe img {
  max-height: 288px;
  min-height: 288px;
  max-width: 288px;
  min-width: 288px;
  object-fit: cover;
}
.available_tokens span {
  color: #000;
}

.is_light .form-edit-profile input::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.4) !important;
}

.is_light .form-edit-profile input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4) !important;
}

.is_light .form-edit-profile input::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.is_dark .form-edit-profile input::-webkit-input-placeholder {
  /* Edge */
  color: rgba(255, 255, 255, 0.2) !important;
}

.is_dark .form-edit-profile input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.2) !important;
}

.is_dark .form-edit-profile input::placeholder {
  color: rgba(255, 255, 255, 0.2) !important;
}

.create-collection li.active {
  border: 1px solid var(--primary-color3) !important;
}
.text-centre {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}

.is_dark .available_tokens span {
  color: #fff;
}
.available_tokens {
  margin-bottom: 20px;
}
.tf-accordion p {
  font-size: 18px;
  font-weight: 800;
  color: var(--golden-desc);
}
.approvetransaction .error_msg.imagesCollection {
  margin-top: 10px !important;
}
.modal .modal-dialog .modal-content .modal-body .item_imgs input {
  border: 0px solid var(--product-color9) !important;
}
.modal .modal-dialog .modal-content .modal-body input {
  border: 1px solid var(--product-color9);
}
.create-item {
  cursor: pointer;
}
.approvetransaction .putonsalede {
  margin-top: 25px !important;
}
.product-media audio {
  max-width: 100%;
  min-width: 100%;
  max-height: 188px;
  min-height: 188px;
}
.Toastify__toast-icon {
  position: absolute;
  left: 0px;
}
.Toastify__close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
.Toastify__toast-body {
  position: relative;
}
.Toastify__toast-body div {
  text-transform: capitalize;
  font-family: "DM Sans", sans-serif !important;
}
.tf-slider-item.style-4 .content-inner form .dropdown > a::after {
  content: none;
}
.tf-slider-item.style-4 .content-inner form .dropdown > a {
  min-width: 80px;
  width: 100%;
}
p.available_tokens {
  font-size: 18px;
  font-weight: 700;
}
h6.available_tokens {
  font-size: 18px;
}
.tf-item-detail-inner video {
  max-height: 564px;
  min-height: 564px;
  max-width: 100%;
  min-width: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.tf-slider-item.style-4 .content-inner form .dropdown > a {
  min-width: 86px;
  width: 86px;
}
.accordion-body div p {
  padding: 16px 20px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--golden-desc);
}
.is_dark .search-item .search-form {
  border: 1px solid var(--golden-border);
}
.is_dark .search-item .search-form input {
  color: rgba(255, 255, 255, 1);
}
.is_dark input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.is_dark input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.is_dark input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
/* .is_dark .preload-container{background:var(--primary-color4) !important} */
.is_dark .react-select__single-value {
  color: rgba(255, 255, 255, 1);
}
.searchCursor {
  cursor: pointer;
}
.Toastify__toast-container {
  width: 365px !important;
}
.Toastify__toast-container .Toastify__toast-body div:nth-child(even) {
  text-align: left;
  max-width: 300px;
  margin-left: 40px;
}
.wishlish:hover .option_popup {
  opacity: 0 !important;
}
.wishlish .option.btn-option:hover .option_popup {
  opacity: 1 !important;
}
.is_light textarea::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}
.is_light textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
.is_light textarea:-moz-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
.is_light textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
.is_light textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
.is_dark textarea::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
  font-size: 14px !important;
}
.is_dark textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
.is_dark textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
.is_dark textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
.is_dark textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
.dashboard-user .dashboard-infor .avatar img {
  max-height: 100%;
  min-height: 100%;
}
.dashboard-user .dashboard-infor .avatar {
  margin-bottom: 30px;
}
.is_dark .modal .modal-dialog .modal-content .modal-body input {
  border: 1px solid var(--golden-border);
  border-radius: 24px;
  background: transparent;
  padding: 9px 20px 9px 20px;
  color: #fff;
  font-size: 14px;
}
.is_light .modal .modal-dialog .modal-content .modal-body input {
  border: 1px solid var(--golden-border);
  border-radius: 24px;
  background: transparent;
  padding: 9px 20px 9px 20px;
  color: #fff;
  font-size: 14px;
}
.tf-slider-item.style-4 .content-inner p {
  margin-bottom: 20px !important;
}
.Toastify__toast-icon + div {
  width: 150px;
}
.swiper-container.slider-home {
  position: relative;
  overflow: unset;
  z-index: 9;
}
.tf-item-detail-inner .img audio {
  width: 100%;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  background: transparent;
  color: #000;
}
.info .tab-bid {
  height: 170px !important;
}
.is_light .react-select__menu {
  background: #1c1c1c !important;
}
.react-select__menu {
  z-index: 9999 !important;
  position: relative;
  background: #1c1c1c !important;
}
.fa.fa-times {
  position: relative;
  right: 12px;
}
.is_dark .price img {
  filter: invert(1);
}
.dashboard-content.inventory
  .inner-content
  .table-ranking
  .title-ranking
  .col-rankingg
  a::after {
  content: none !important;
}
.auction span {
  display: none !important;
}
.tf-button.edit_offer {
  padding: 10px 36px;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 13px;
}
.tf-button.edit_offer:hover {
  border-color: #fff;
  transition: all 0.5s ease;
}
.audImgVid.product-media audio {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: auto;
  position: absolute;
  z-index: 99999;
  bottom: 130px;
  left: 0;
  border-radius: 30px;
}
.is_dark .featured-countdown span {
  color: #000;
}
.sc-product .features .product-media img {
  position: relative;
  z-index: 0;
}
.info audio {
  width: 100%;
}
/* .sc-product .features .product-media{overflow:auto !important} */

.sc-product .features .product-media {
  border-radius: 0px !important;
}
.is_dark .featured-countdown span,
.is_dark .modal .modal-title,
.is_dark .modal h2,
.is_dark .modal p {
  color: #fff;
}
.dashboard a {
  width: 100%;
  display: block;
}
.approvetransaction .item_imgs audio {
  position: relative;
  bottom: 40px;
}
.sc-product .features .featured-countdown {
  padding: 4px 13px 4px 17px !important;
}
.info .tf-item-detail-inner iframe {
  min-height: 564px !important;
  max-height: 564px !important;
}
.is_dark .sc-product .features .featured-countdown {
  background-color: #000;
}
.propertys {
  background: rgba(255, 255, 255, 0.5);
  width: 100px;
  padding: 5px 10px;
  border-radius: 30px;
}
.propertys .close i {
  color: #fff;
  position: relative;
  top: 0px;
  right: 0px;
}
.rootProperty .propertys {
  margin-right: 20px;
}
.is_light .propertys {
  background-color: #191820;
}
.is_dark .propertys {
  background-color: #fff;
}
.is_light .propertys .color {
  color: #fff;
}
.is_dark .propertys .color {
  color: #191820;
}
.propertys .color {
  font-size: 13px;
}
.propertys i {
  font-size: 13px;
}
.is_light .propertys i {
  color: #fff;
}
.is_dark .propertys i {
  color: #191820;
}
.dashboard-user .dashboard-infor .avatar img {
  min-height: 120px;
  max-height: 120px;
  object-fit: cover;
}
.tab-details .properties i {
  position: relative;
  right: -7px;
  top: -1px;
}
.button_add_more button {
  font-size: 14px;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  /* border: 1px solid transparent !important; */
}
.putonsale .item_imgs img {
  min-height: 220px !important;
  max-height: 220px !important;
}
.card-author .avatar img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.button-top .avatar {
  position: absolute;
  bottom: -105px;
  right: 42px;
}
.avatar.second {
  position: absolute;
  bottom: -17px;
  right: 0;
  left: 6px;
}
.approvetransaction .avatar .edit_profile {
  background: #ff008e;
  position: absolute;
  top: 36px;
}
.sc-product.style1.without_img_card {
  min-height: 514px !important;
  max-height: 514px !important;
}
.sc-product.style1.without_img_card .features .product-media {
  min-height: 514px !important;
  max-height: 514px !important;
}

.sc-product.style1.without_img_card .features .product-media img {
  min-height: 475px !important;
  max-height: 475px !important;
  object-fit: contain !important;
}
.header .header-center {
  background: linear-gradient(
    272.72deg,
    #d38504 23.6%,
    #bc7a0d 36.88%,
    #fec168 56.31%,
    #c77f08 71.77%,
    #d48604 85.31%
  );
  box-shadow: 0px 2px 0px #f8b84f;
  border-radius: 0px 35px;
  margin: auto;
}
.tf-button {
  background: url(../images/common_btn.png) !important;
  border: none !important;
  background-size: 100% 100% !important;
  border-radius: 0px !important;
}
.tf-button:hover {
  background: url(../images/common_btn_hover.png) !important;
  background-size: 100% 100% !important;
}
.header .tf-button {
  background: url(../images/wallet_btn.png) !important;
  border: none !important;
  background-size: 100% 100% !important;
  border-radius: 0px !important;
}
.header .tf-button:hover {
  background: url(../images/wallet_btn_hover.png) !important;
  background-size: 100% 100% !important;
}

.sc-product .card_frame {
  background: url(../images/card-frame-final.png);
  position: absolute;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 0px;
  top: 0px;
}
.sc-product .card-label {
  position: absolute;
  background: #171717;
  border-radius: 0px 15px 3px 0px;
  z-index: 2;
  bottom: 24%;
  padding: 8px;
  left: 10px;
}
.sc-product .bottom .product-button {
  position: absolute;
  z-index: 3;
  bottom: 30px;
  right: 30px;
}
.sc-product .bottom .product-button .tf-button {
  background: linear-gradient(
    90.85deg,
    #dc9834 0.85%,
    #bf7b0c 53.74%,
    #d38504 99.44%
  ) !important;
  border-radius: 3px 3px 3px 17px !important;
}
.sc-product .blur_shadow {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.15) 100%);
  -webkit-backdrop-filter: blur(6.5px);
  backdrop-filter: blur(6.5px);
  width: 95%;
  height: 120px;
  position: absolute;
  z-index: 1;
  left: 0px;
  bottom: 0px;
  margin-left: 10px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.sc-product .top .wish-list {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 2;
}
.sc-product .top {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.button-follow {
  background: linear-gradient(
    90.85deg,
    #dc9834 0.85%,
    #bf7b0c 53.74%,
    #d38504 99.44%
  );
  border-radius: 15px 15px 0px 15px;
  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid transparent;
}
.button-follow:hover {
  background: transparent;
  color: white;
  border: 1px solid #c87f21;
}
.footer hr {
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.footer .icon_row {
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer .icon_row a i {
  color: #d38504;
}
.footer .icon_row a span {
  margin-left: 10px;
  font-weight: 400;
}
.footer .widget.widget-infor .logo {
  margin-left: -50px;
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .footer .widget.widget-subcribe {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .menu-item a.active {
    color: #dc9834 !important;
  }
}
.Toastify__progress-bar--error {
  background: var(--golden-primary) !important;
}
.Toastify__toast-theme--light {
  background: var(--golden-tab-card) !important;
  color: #fff !important;
}
.filter-menuu .dashboard img path {
  fill: var(--golden-tab-card);
}
.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
}
.modal-header {
  border-bottom: 1px solid var(--golden-border);
}
.modal .primary {
  padding: 15px 36px;
}
.dashboard-user .dashboard-filter .filter-menuu .dashboard .hoverimage {
  display: none;
}
.dashboard-user .dashboard-filter .filter-menuu .dashboard.active .hoverimage {
  display: inline-block;
}
.dashboard-user .dashboard-filter .filter-menuu .dashboard.active .showimage {
  display: none;
}
.dashboard-user .dashboard-filter .filter-menuu li.active,
.dashboard-user .dashboard-filter .filter-menuu li:hover .showimage {
  display: none;
}
.dashboard-user .dashboard-filter .filter-menuu li.active,
.dashboard-user .dashboard-filter .filter-menuu li:hover .hoverimage {
  display: inline-block;
}
.dashboard-user .follow_btn .tf-button {
  padding: 10px 30px;
  font-size: 14px;
}
.tf-button:focus {
  box-shadow: none !important;
}
.react-select__option:hover {
  background: var(--golden-tab-card) !important;
}

@media only screen and (max-width: 575px) {
  .tf-banner-collection .tf-heading .sub-heading {
    font-size: 9px !important;
  }
  #site-header-inner #logo_header {
    margin-left: 0px;
  }
  .footer .widget.widget-infor .logo {
    margin-left: 0px;
  }
  .footer .widget.widget-subcribe {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .footer ul.social-item {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 991px) {
  .is_dark .search-item .search-form {
    border: 1px solid var(--golden-desc);
  }
}
.search-model .contentTop .tf-button {
  width: 120px;
  padding: 10px 36px;
  margin: auto;
}
/* .nftnavscroll{
    max-width: 200px;
    max-height: 400px;
    overflow-y: auto;
} */

.is_dark .search .tf-author {
  background: var(--golden-card);
  border: 1px solid var(--golden-border);
}
.is_dark .search .tf-author:hover {
  box-shadow: 1px 0px 5px 2px #d78520;
  border: 1px solid var(--golden-border);
}
.is_dark .search .tf-author .content .title a:hover {
  color: var(--golden-text);
}
.sc-product.collection2 .top .wish-list {
  position: unset;
}
.sc-product.collection2 .thumb-collection img {
  width: 200px;
}
@media only screen and (min-width: 1600px) {
  .tf-item-detail-inner .image {
    width: 40%;
  }
}
.Toastify__progress-bar--success {
  background: var(--golden-primary) !important;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: inherit !important;
  transform: initial !important;
  right: 1em !important;
}

.footer #subscribe-button i {
  font-size: 24px;
}
.walletconnect-modal__mobile__toggle a {
  color: #000 !important;
}
.walletconnect-modal__base {
  min-height: 75vh;
}
.sc-product .featured-countdown {
  position: absolute;
  z-index: 4;
  right: 6px;
  top: 10px;
}
.sc-product .featured-countdown span {
  font-size: 11px;
}
.sc-product .featured-countdown .rem_title {
  color: #ec9503;
  font-size: 16px;
}

.footer .social-item {
  display: flex;
  gap: 20px;
}

.footer .social-item a span {
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
}
.footer .social-item a {
  display: flex;
  align-items: center;
}
.footer .social-item a:hover span {
  color: var(--golden-border);
}
.footer .social-item i {
  font-size: 24px;
}

/* .explore_anim[data-aos^=zoom-in-up]{
    opacity: 1;
    transform: translateZ(0) scale(1);
} */

.tf-author .user_pf_img {
  max-width: 45px;
  min-width: 45px;
  object-fit: cover !important;
  min-height: 45px;
  max-height: 45px;
}
.search-model .searchCursor img {
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  object-fit: cover;
  border-radius: 50px;
}
.search-model .tf-button a:hover {
  color: #fff;
}
.Toastify__toast-icon svg {
  fill: #fff !important;
}
.nodata_found,
.load_datas {
  padding-top: 30px;
  font-size: 25px;
  padding-bottom: 30px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
.sc-product .top .wish-list a {
  background: url("../images/baselike.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}
.sc-product .top .wish-list a:hover {
  background: url("../images/activelike.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}
.sc-product .top .wish-list.active a {
  background: url("../images/activelike.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}
.sc-product .top .wish-list a::after {
  display: none;
}

@media only screen and (min-width: 992px) {
  .filter-menu {
    flex-wrap: wrap;
  }
  .filter-menu li {
    margin-bottom: 20px;
  }
}
.Toastify__progress-bar-theme--light-bar {
  background: var(--golden-primary) !important;
}
.dashboard-infor .social-item i {
  color: var(--golden-text);
}
.dashboard-infor .social-item i:hover {
  color: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--golden-text);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--golden-text);
}

.calendar_modal_zindex {
  z-index: 1000000 !important;
}
.new_hy.hy {
  width: 100%;
  padding: 0;
}
.scroll_topnft {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 275px;
  max-height: 275px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.xtwitter {
  font-size: 24px;
  color: #d38504;
}
.Xtwitter {
  background: #171717;
  box-shadow: 2px 5px 12px 5px rgb(0 0 0 / 48%);
  border-radius: 0px 25px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cb8428;
  cursor: pointer;
}

/* 11-12-23 sakthi */

.airdrop_page .banner_section {
  background-image: url("../images/airdrop/poster1.png");
  /* background-repeat: no-repeat; */
  /* background-size: 100% 100%; */
  /* width: 100%; */
  /* height: 100%; */
  /* background-size: cover; */
  width: 100%;
  height: 860px;
  /* background-position: center; */
  /* height: 100vh;
  background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}
.airdrop_page .banner_section .tf-button {
  display: flex;
  align-items: center;
  /* position: absolute; */
  /* bottom: 83px; */
  /* left: 43%; */
}
.airdrop_page .banner_section .know_more_btn {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
}
.airdrop_page .tf-button .down_arrow {
  font-size: 30px;
}
.airdrop_page .banner_section {
  position: relative;
}
.airdrop_page .claim_nft {
  /* background-image: url("../images/airdrop/claim_bg.png"); */
  background-image: url("../images/airdrop/claim_bg2.png");
  width: 100%;
  /* height: 100vh;
  background-position: center; */
  /* height: 800px; */
  height: 92vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.airdrop_page .claim_nft button {
  border-radius: 14px 14px 0px 14px;
  border: 1px solid var(--golden-border);
  background: linear-gradient(180deg, #b76d00 0%, #db8b05 100%);
  position: absolute;
  bottom: 25%;
  left: 41%;
}

.airdrop_page .claim_nft button:hover {
  background: transparent;
  border: 1px solid var(--golden-border);
}

.airdrop_page .join_conversation a i {
  color: #d38504;
  font-size: 21px;
}
.airdrop_page .join_conversation a i:hover {
  color: white;
}

.airdrop_page .join_conversation a i {
  background: #171717;
  box-shadow: 2px 5px 12px 5px rgba(0, 0, 0, 0.48);
  border-radius: 0px 25px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.airdrop_page .join_conversation .heading {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  font-weight: 700;
}
.airdrop_page .join_conversation .cnt_txt {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 26px;
  font-weight: 500;
  text-align: initial;
  line-height: 35px;
}
.airdrop_page .join_conversation .content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0px;
}
.airdrop_page .join_conversation .content .icons_sec {
  display: flex;
  align-items: center;
  gap: 30px;
  /* padding: 80px 0px; */
}

.airdrop_page .exclusive_airdrop {
  margin: 80px auto;
}
.airdrop_page .exclusive_airdrop h4 {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Bestigia;
  position: relative;
  display: inline;
}

.airdrop_page .exclusive_airdrop h4::after {
  content: "";
  width: 70px;
  height: 100px;
  background: url("../images/airdrop/fire_drop.png") no-repeat;
  top: -48px;
  right: -48px;
  background-size: 100% 100%;
  position: absolute;
  display: inline-block;
}

.airdrop_page .exclusive_airdrop h5 {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Bestigia;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Bestigia;
}

.airdrop_page .exclusive_airdrop p {
  color: #c6c6c6;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.airdrop_page .participate {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Ubuntu;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom_participate_container {
  min-width: 90% !important;
}
.custom_claim_nft_container {
  min-width: 90% !important;
}
.airdrop_page .participate .heading {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Ubuntu;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 15px;
}
.airdrop_page .participate .content {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
  text-align: center;
}

.airdrop_page .participate .seamless_before::before {
  content: "";
  width: 100px;
  height: 100%;
  background: url("../images/airdrop/before_seamless.png") no-repeat;
  top: 0px;
  left: 0px;
  background-size: 100% 100%;
  position: absolute;
  display: inline-block;
}

.airdrop_page .participate .exclusive_nft,
.airdrop_page .participate .seamless,
.airdrop_page .participate .minimal_gas,
.airdrop_page .participate .community_building {
  margin-bottom: 80px;
}

.airdrop_page .participate .inner_cus_container {
  margin-top: 30px;
  margin-bottom: 80px;
}

.custom_claim_your_nfts_container {
  min-width: 90%;
  /* position: relative; */
}
.claim_your_nfts .custom_bg_add {
  position: relative;
}
.claim_your_nfts .row_sec_content {
  /* position: absolute; */
}

.airdrop_page .claim_your_nfts .header_txt {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Ubuntu;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 80px;
}

.airdrop_page .participate .header_txt {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Ubuntu;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.airdrop_page .claim_your_nfts .card_bg {
  border-radius: 16px;
  background: #171717;
  height: 90px;
  top: 30px;
  width: 100%;
  position: absolute;
}

.airdrop_page .claim_your_nfts .heading_txt {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Ubuntu;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.airdrop_page .claim_your_nfts .content {
  background: linear-gradient(
    180deg,
    #fff 0%,
    #919191 0.01%,
    #e1e1e1 52.08%,
    #a9a9a9 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}

.airdrop_page .claim_your_nfts .common_img {
  height: 200px;
}

.airdrop_page .claim_your_nfts .nft_minted_cmn_bg {
  height: 280px;
  /* position: relative;
  top: -70px    ; */
}
.airdrop_page .claim_your_nfts .nft_mint {
  position: relative;
  top: -77px;
}

.airdrop_page .participate .cmn_bg {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.airdrop_page .join_conversation .col_datas {
  display: flex;
  justify-content: space-between;
}
.airdrop_page .join_conversation .col_datas .cnt_txt {
  width: 67%;
}

@media (max-width: 400px) {
  .airdrop_page .claim_your_nfts .header_txt {
    margin-bottom: unset !important;
    font-size: 23px;
  }
  .airdrop_page .claim_your_nfts .card_bg {
    height: 100%;
    top: 0px;
  }
  .airdrop_page .claim_your_nfts .common_img {
    height: 150px;
  }
  .airdrop_page .claim_your_nfts .nft_minted_cmn_bg {
    height: 200px;
  }
  .airdrop_page .claim_your_nfts .nft_mint {
    position: unset !important;
  }
}

@media (max-width: 500px) {
  .airdrop_page .exclusive_airdrop h4 {
    font-size: 25px;
  }
  .airdrop_page .exclusive_airdrop h5 {
    font-size: 35px;
  }
  .airdrop_page .exclusive_airdrop h4::after {
    width: 48px;
    height: 70px;
    top: -32px;
    right: -32px;
  }
  .airdrop_page .exclusive_airdrop p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .airdrop_page .join_conversation .heading {
    font-size: 26px;
  }
  .airdrop_page .join_conversation .content {
    margin-top: 20px;
  }
  .airdrop_page .join_conversation .col_datas {
    display: unset !important;
  }
  .airdrop_page .join_conversation .col_datas .cnt_txt {
    width: 100%;
  }
  .airdrop_page .join_conversation .cnt_txt {
    font-size: 19px;
    line-height: unset;
    text-align: center;
  }
}

@media (max-width: 460px) {
  .airdrop_page .claim_nft button {
    padding: 5px 10px;
    font-size: 10px;
    bottom: 10%;
    left: 26%;
  }
}

@media (min-width: 461px) and (max-width: 500px) {
  .airdrop_page .claim_nft button {
    padding: 5px 10px;
    font-size: 10px;
    bottom: 10%;
    left: 33%;
  }
}

@media (min-width: 501px) and (max-width: 600px) {
  .airdrop_page .claim_nft button {
    padding: 5px 10px;
    font-size: 10px;
    bottom: 10%;
    left: 33% !important;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .airdrop_page .exclusive_airdrop h4 {
    font-size: 25px;
  }
  .airdrop_page .exclusive_airdrop h5 {
    font-size: 35px;
  }
  .airdrop_page .exclusive_airdrop p {
    font-size: 15px;
  }
  .airdrop_page .exclusive_airdrop h4::after {
    height: 80px;
  }

  /* .airdrop_page .claim_nft button {
    padding: 5px 10px;
    font-size: 10px;
    bottom: 10%;
    left: 26%;
  } */

  .airdrop_page .claim_nft button {
    /* padding: 5px 10px; */
    font-size: 12px;
    bottom: 10%;
    left: 33%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .airdrop_page .claim_nft button {
    /* padding: 5px 10px; */
    font-size: 12px;
    bottom: 20%;
    left: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .airdrop_page .exclusive_airdrop h4 {
    font-size: 25px;
  }
  .airdrop_page .exclusive_airdrop h5 {
    font-size: 35px;
  }
  .airdrop_page .exclusive_airdrop p {
    font-size: 15px;
  }
  .airdrop_page .exclusive_airdrop h4::after {
    height: 80px;
  }
  .airdrop_page .claim_nft button {
    /* padding: 5px 10px; */
    font-size: 12px;
    bottom: 20%;
    left: 40%;
  }
}

@media (max-width: 991px) {
  .airdrop_page .claim_your_nfts .card_bg {
    height: 100%;
    top: 0px;
  }
  .airdrop_page .claim_your_nfts .nft_mint {
    position: unset !important;
  }
  .airdrop_page .claim_your_nfts .header_txt {
    margin-bottom: unset !important;
  }
  .airdrop_page .claim_your_nfts .nft_minted_cmn_bg {
    height: 200px;
  }
}

@media (min-width: 992px) {
  .claim_your_nfts .custom_bg_add {
    padding: 0px 30px;
  }
  .airdrop_page .claim_your_nfts .card_bg {
    width: 93%;
  }
}

@media (max-width: 1199px) {
  .airdrop_page .participate .seamless_before::before {
    left: -45px;
  }
  .airdrop_page .participate .cmn_bg {
    width: 250px;
    height: 250px;
    object-fit: contain;
  }
  /* .get_free_nft .free_nft {
    width: 200px !important;
    height: 200px !important;
  } */
}

@media (max-width: 576px) {
  .airdrop_page .participate .inner_cus_container {
    margin-top: 0px;
  }
  .airdrop_page .claim_nft {
    /* background-image: url("../images/airdrop/mobile\ poster.png"); */
    background-image: url("../images/airdrop/mobile_poster1.png");
    height: 35vh !important;
  }
}

@media (max-width: 400px) {
  .airdrop_page .banner_section {
    height: 37vh;
    background-position: center;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 12px;
    /* left: 25%; */
    /* padding: 7px 25px; */
  }
  .airdrop_page .banner_section .tf-button {
    padding: 4px 20px;
  }

  .airdrop_page .claim_nft {
    height: 25vh;
  }
}
@media (min-width: 401px) and (max-width: 560px) {
  .airdrop_page .banner_section {
    height: 45vh;
    background-position: center;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 20px;
    /* left: 32%; */
    /* padding: 7px 25px; */
  }

  .airdrop_page .claim_nft {
    height: 40vh;
  }
}
@media (min-width: 561px) and (max-width: 760px) {
  .airdrop_page .banner_section {
    height: 32vh;
    background-position: center;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 9px;
    /* left: 35%; */
    /* padding: 4px 20px; */
  }
  .airdrop_page .banner_section .tf-button {
    padding: 4px 20px;
  }

  .airdrop_page .claim_nft {
    height: 40vh;
  }
}

@media (min-width: 761px) and (max-width: 980px) {
  .airdrop_page .banner_section {
    height: 42vh;
    background-position: center;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 9px;
    /* left: 35%; */
    /* padding: 7px 25px; */
  }

  .airdrop_page .claim_nft {
    height: 40vh;
  }
}

/* @media (min-width: 801px) and (max-width: 980px) {


} */

@media (min-width: 981px) and (max-width: 1024px) {
  .airdrop_page .banner_section {
    height: 56vh;
    background-position: center;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 9px;
    /* left: 35%; */
    padding: 7px 25px;
  }
  .airdrop_page .claim_nft {
    height: 50vh;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .airdrop_page .banner_section {
    height: 75vh;
    background-position: center;
    background-size: contain;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 90px;
    /* left: 35%; */
    padding: 7px 25px;
  }

  .airdrop_page .claim_nft {
    height: 50vh;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .airdrop_page .banner_section {
    height: 75vh;
    background-position: center;
    background-size: contain;
  }
  .airdrop_page .banner_section .know_more_btn {
    bottom: 70px;
    /* left: 43%; */
  }

  .airdrop_page .claim_nft {
    height: 70vh;
  }

  .airdrop_page .claim_nft button {
    /* padding: 5px 10px; */
    font-size: 15px;
    bottom: 20%;
    left: 40%;
  }
  .airdrop_page .join_conversation {
    margin-top: 40px;
  }
}

@media (min-width: 1401px) {
  .airdrop_page .banner_section {
    height: 100vh;
    background-position: center;
    background-size: contain;
  }
  /* .airdrop_page .banner_section .tf-button{
    bottom: 70px;
  } */
  .airdrop_page .banner_section .know_more_btn {
    bottom: 120px;
  }

  .airdrop_page .claim_nft {
    height: 100vh;
  }

  .airdrop_page .claim_nft button {
    /* padding: 5px 10px; */
    font-size: 16px;
    bottom: 30%;
    left: 40%;
  }
  .airdrop_page .join_conversation {
    margin-top: 70px;
  }
}

@media (max-width: 991px) {
  /* .airdrop_page .participate .inner_cus_container {
    margin-bottom: 0px;
  } */
  .claim_your_nfts .row_sec_content {
    padding-top: 50px;
  }
}

@media(max-width:992px){
  .airdrop_page .participate .inner_cus_container {
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 100vh !important;
  }
}

@media (max-width: 400px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 37vh !important;
    background-position: center;
    /* background-size: contain; */
  }
}

@media (min-width: 401px) and (max-width: 560px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 42vh !important;
    background-position: center;
    background-size: contain;
  }
}

@media (min-width: 561px) and (max-width: 760px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 42vh;
    background-position: center;
    background-size: contain;
  }
}

@media (min-width: 761px) and (max-width: 980px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 55vh;
    background-position: center;
    background-size: contain;
  }
}

@media (min-width: 981px) and (max-width: 1024px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 70vh;
    background-position: center;
    background-size: contain;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) and (max-height: 680px) {
  .airdrop_page .banner_section {
    height: 100vh;
    background-position: center;
    background-size: contain;
  }
}

@media (min-width: 601px) and (max-width: 1200px) {
  .airdrop_page .claim_nft button {
    padding: 10px 20px !important;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .airdrop_page .claim_nft button {
    left: 38% !important;
  }
}
/* .get_free_nft .free_nft {
  width: 240px !important;
  height: 240px !important;
} */
@media (max-width: 575px) {
  .get_free_nft {
    margin-bottom: 0px !important;
  }
  .get_free_nft .free_nft {
    width: 200px !important;
    height: 200px !important;
  }
  /* .continuous_earning {
    margin-bottom: 30px !important;
  } */
  /* .seamless_before {
    margin-bottom: 30px !important;
  } */
  /* .get_free_nft .free_nft{
    height: 200px !important;
  } */
  .airdrop_page .participate .cmn_bg {
    height: unset;
  }
}

@media (max-width: 1199px) {
  /* .get_free_nft .free_nft {
      width: 200px !important;
      height: 200px !important;
    } */
}
