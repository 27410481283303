.homepage .banner_sec{
    background-image: url(../images/banner_bg.png);
    background-size: 100% 100%;
    min-height: 100vh;  
}
.set-item1 .react-select__option:hover {
    color: #fff !important;
    transition: all 0.5s ease;
}
body.modal-open {
    position: fixed !important;
    height: 100vh !important;
    overflow-y: hidden !important;
  }
.modal::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: black;
}

.modal::-webkit-scrollbar
{
	width: 12px;
	background-color: black;
}

.modal::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: black;
}
.homepage .banner_sec span:after{
    content: "";
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAZCAYAAAC7OJeSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMzSURBVHgBzZbPaxNBFMff7I9k227MWJsSjOLqSUFwxYMKgusPEAUxFBQRwfXH0R/Vi6BIFL2IQjx6Szx58NCKxx5SoaAnG/+BZuNBRLSdbZPubrKz425KS9q0Nqnb4gdmf7zZ2X373nfeDIxnMA4a/AdwxLWzfjsHa2DsUTT7KSMpEBIcmWVFUkUqdMjQoKRUHJQ+/MQ2ICQEYqGvjHFp6JBqncu4HuQhRARiS0WP1vd1MiirY1yx61pdpMcgRLi7eUKmZ3nz2YC0o91BM1WanrZh9Orr8FLUcCY4TPov/umI2tLOwoNIbrlB1RqXmamhPIRMwxnT5oqmxS8Scc5PxXdTaNHSjZNY84ULT99VPkLICMFhcoYv88hb9GHDBhyxeLJ0gOnAlYgLj2EdaESmUqOjkxa3KDLlX6AYvzmj2aapWKnanF61oSUq7RbOwn1JGctEsys6M1okxLQ4sn8nXhCx8UOA8hTHmh+u2qJWtVF++DMxmu3vBrsG/cI5BKvw9mb3Y8uDAqVgLNcvzF+YFioy1hDxm+D+m4kUHgnl5od9rWRcF+nNtqcXYumpCr0t2Ow4rEBW71F7BOZPBo84dTh25oVjwN9IJBIqxlhptvn3C6FPJpPatq2JL839lzSs3jsbL2UuLr8kDKYxzpzf9OrFZXny9fWuO7AKCDogcJaQuRQd2p1UtmyqFfp7PD1XIC0aCmad3MVyMZGOc55w7ckwIbAeBBHbtb2/dHBPb8vfahrG54/0vtJPxCdunZaPwkaQSqVaFtcje/u0Uwd6SwOHcTatdb4t6ShNK6EoGHcjISdLTI1LVB8ZJ2sqiBz8I319fVrdEScoA6Nm0f1rdSQ0lkvZPCMPJQ3aJJQ0NRNoZZsEan/cPZeKM33rZreEJTTQziZMgJBIJRN6Ms6uUMtTbZEWmceGmUjV08+dcrvvCM2ZAMpQfsbx3n8YMdenpmwkoWoGN5AwFimWeIZj3UyJSRRkCXbIEQ9iElPkKIAc8c+S3+83OUrjvm1zJEK10NKk+MWm5syWROYRhBgRRUZ4xAze/12BA8NvIPAwyvvnqOAaDKgpevxU3e0ytZdzS8UfprtCg98cCmYAAAAASUVORK5CYII=);
    background-size: 100% 100%;
    height: 60px;
    width: 65px;
    top: 7px;
}
.homepage .banner_sec .banner_row{
    align-items: center ;
    padding-top: 100px;
}
.homepage .banner_sec  .social_icons i{
    background: #171717;
    box-shadow: 2px 5px 12px 5px rgba(0, 0, 0, 0.48);
    border-radius: 0px 25px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cb8428;
    cursor: pointer;
}
.homepage .banner_sec .social_icons i:hover{
    color: #c7c7c7;
}
.homepage .banner_sec  .social_icons{
display: flex;
gap: 150px;
margin-top: 50px;
}
.homepage .banner_sec  .follow_sec{
    position: relative;
}
.follow_sec::before{
    content: "";
    position: absolute;
    border-top: 2px solid #DB8B05;
    width: 100px;
    left: -75%;
    top: 33%;
}
.homepage .banner_sec  .social_icons .icons_sec{
    display: flex;
    gap: 10px;
}

.homepage .banner_sec  .social_icons .icons_sec p{
    position: absolute;
    bottom: -2px;
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.13em;
text-transform: uppercase;
color: #FFFFFF;
opacity: 0.1;
}
.homepage .banner_sec .follow_sec{
    display: flex;
    gap: 50px;
    
}
.homepage .banner_sec .follow_sec .followcard{
    display: flex;
    background: #171717;
border: 1px solid rgba(255, 255, 255, 0.2);
border-radius: 20px 20px 0px 20px;
height: 50px;
width: 50px;
align-items: center;
justify-content: center;
font-weight: 700;
}
.homepage .banner_sec .follow_sec p.second{
    background: linear-gradient(90.85deg, #DC9834 0.85%, #BF7B0C 53.74%, #D38504 99.44%);
    color: #000;


}
.homepage .banner_sec .follow_sec  .fl_name{
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
text-align: end;
text-transform: uppercase;

color: #FFFFFF;
opacity: 0.7;
margin-left: -40px;
margin-top: 15px;
}
.banner_sec .mini-head{
    font-family: 'Bhavuka';
font-style: normal;
font-weight: 400;
font-size: 35px;
line-height: 44px;
color: #FFFFFF;
}
.banner_sec .banner_title{
    font-family: 'Bestigia' !important;
font-style: normal;
font-weight: 400;
font-size: 75px;
line-height: 70px;

background: linear-gradient(90deg, #8C8C8C 0%, #CECECE 27.08%, #E2E2E2 49.48%, #DEDEDE 73.96%, #A7A7A7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.banner_sec .banner_desc{
    
font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
/* or 175% */

text-align: justify;

color: #C7C7C7;
}
.homepage .sec_title{
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 46px;

background: linear-gradient(90deg, #B9B9B9 0%, rgba(255, 255, 255, 0.84375) 13.54%, #B9B9B9 28.13%, #FFFFFF 38.54%, #B0B0B0 53.13%, rgba(255, 255, 255, 0.99) 62.5%, rgba(167, 167, 167, 0.99) 76.04%, rgba(255, 255, 255, 0.99) 86.46%, rgba(187, 187, 187, 0.99) 97.4%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
position: relative;
}
.homepage .sec_title span{
    position: relative;
    display: contents;
}
.homepage .sec_title span:after{
    content: "";
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAZCAYAAAC7OJeSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMzSURBVHgBzZbPaxNBFMff7I9k227MWJsSjOLqSUFwxYMKgusPEAUxFBQRwfXH0R/Vi6BIFL2IQjx6Szx58NCKxx5SoaAnG/+BZuNBRLSdbZPubrKz425KS9q0Nqnb4gdmf7zZ2X373nfeDIxnMA4a/AdwxLWzfjsHa2DsUTT7KSMpEBIcmWVFUkUqdMjQoKRUHJQ+/MQ2ICQEYqGvjHFp6JBqncu4HuQhRARiS0WP1vd1MiirY1yx61pdpMcgRLi7eUKmZ3nz2YC0o91BM1WanrZh9Orr8FLUcCY4TPov/umI2tLOwoNIbrlB1RqXmamhPIRMwxnT5oqmxS8Scc5PxXdTaNHSjZNY84ULT99VPkLICMFhcoYv88hb9GHDBhyxeLJ0gOnAlYgLj2EdaESmUqOjkxa3KDLlX6AYvzmj2aapWKnanF61oSUq7RbOwn1JGctEsys6M1okxLQ4sn8nXhCx8UOA8hTHmh+u2qJWtVF++DMxmu3vBrsG/cI5BKvw9mb3Y8uDAqVgLNcvzF+YFioy1hDxm+D+m4kUHgnl5od9rWRcF+nNtqcXYumpCr0t2Ow4rEBW71F7BOZPBo84dTh25oVjwN9IJBIqxlhptvn3C6FPJpPatq2JL839lzSs3jsbL2UuLr8kDKYxzpzf9OrFZXny9fWuO7AKCDogcJaQuRQd2p1UtmyqFfp7PD1XIC0aCmad3MVyMZGOc55w7ckwIbAeBBHbtb2/dHBPb8vfahrG54/0vtJPxCdunZaPwkaQSqVaFtcje/u0Uwd6SwOHcTatdb4t6ShNK6EoGHcjISdLTI1LVB8ZJ2sqiBz8I319fVrdEScoA6Nm0f1rdSQ0lkvZPCMPJQ3aJJQ0NRNoZZsEan/cPZeKM33rZreEJTTQziZMgJBIJRN6Ms6uUMtTbZEWmceGmUjV08+dcrvvCM2ZAMpQfsbx3n8YMdenpmwkoWoGN5AwFimWeIZj3UyJSRRkCXbIEQ9iElPkKIAc8c+S3+83OUrjvm1zJEK10NKk+MWm5syWROYRhBgRRUZ4xAze/12BA8NvIPAwyvvnqOAaDKgpevxU3e0ytZdzS8UfprtCg98cCmYAAAAASUVORK5CYII=);
    background-size: 100% 100%;
    height: 35px;
    width: 35px;
    top: -10px;
    right: -20px;
}
.homepage .content_sec .sec_title span:after{
   
    top: 30px;
    right: unset;

}
/* .homepage .banner_title span{
    position: relative;
    display: contents;
}
.homepage .banner_title span:after{
    content: "";
    position: absolute;
    background: url("../images/banner_element.png");
    background-size: 100% 100%;
    height: 100px;
    width: 100px;
   
    top: -14px;
    right: 24%;
} */
.homepage .sec_desc{
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #C7C7C7;

}
.topnft_sec .top_nft_card{
    background: #171717;
    border-radius: 25px 0px 0px 25px;
    padding: 20px;
    padding-left: 40px;
    margin-right: -5%;
    padding-right: 50px;

}

.topnft_sec .nav-pills .nav-link.active{
    background: none !important;
}

.align_sec{
    align-items: center;
}

.homepage .owl-carousel .owl-nav .owl-prev{
    background: url('../images/left-arrow.png') no-repeat;
    width: 50px;
    height: 50px;
}
.homepage .owl-carousel .owl-nav .owl-prev span,
.homepage .owl-carousel .owl-nav .owl-next span{
   display: none;
}
.homepage .owl-carousel .owl-nav .owl-next{
    background: url('../images/right-arrow.png') no-repeat;
    width: 50px;
    height: 50px;
}
.homepage .owl-carousel .owl-item img {
    display: unset !important;
    width: unset !important;
}

.homepage .top_nft_card .owl-carousel .owl-nav .owl-next{
    display: none;
}
/* .homepage .top_nft_card  .owl-carousel-vertical{
    transform: rotate3d(0, 0, 1, 90deg);
  }
  
  .homepage .top_nft_card  .owl-carousel-vertical .item{
    transform: rotate3d(0, 0, 1, -90deg);
  }
  
  .homepage .top_nft_card  .owl-nav{ transform: rotate3d(0, 0, 1, 0deg);} */

.homepage .top_nft_card .owl-carousel .owl-nav .owl-prev{
    background: url('../images/up-arrow.png') no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    right: -30px;
    top: 33%;
}
.topnft_sec .nav-pills .nav-link.active img{
    transform: scale(2);
    padding: 2px;
    background: var(--golden-btn);
}
.topnft_sec .nav-pills .nav-link img{
    border-radius: 50px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    transition: transform .2s;
}
.topnft_sec .owl-carousel .owl-item.active{
 padding-bottom: 30px;
}
.topnft_sec .owl-carousel .owl-item.active~.owl-item:first-child{
    position: absolute;
    top: 250px;
    left: -300px;
}
.top_nft_card .title{
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
position: relative;

color: #DB8B05;
}
.top_nft_card .desc{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #C7C7C7;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
}
.top_sec .create_name{
    font-family: 'Ubuntu';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 37px;

color: #FFFFFF;

}
.top_sec .create_desc{
font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 23px;

color: #DB8B05;
}
.top_sec .create_price{

font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
color: #FFFFFF;
opacity: 0.61;
}
.creators_row .rightsec .top_imf_sec .img_top{
    border-radius: 50px;
    width: 70px !important;
    height: 150px;
    object-fit: cover;

}
.creators_row .rightsec .top_imf_sec{

display: flex;
align-items: center;
justify-content: center;
}
.top_sec .item.fade_effect{
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}
.top_sec .owl-nav{
    display: none;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
     }
}
.top_sec .left_sec .pf_big{
    border-radius: 25px 0px;
}
.top_sec{
    height: 380px;
}
.top_sec .arrow_sec{
    position: absolute;
    left: 55%;
    top: 40%;
    background: linear-gradient(90.85deg, #DC9834 0.85%, #BF7B0C 53.74%, #D38504 99.44%);
    border-radius: 50px;
    padding: 10px;
    z-index: 9;
    cursor: pointer;
}
.top_sec .rightsec .top_imf_sec{
    position: relative;
}
.top_sec .creators_card{
    background: #171717;
border-radius: 25px 25px 25px 0px;
height: 150px;
}
.top_sec .content_sec {
    margin-top: 15px;
    padding-left: 45px;
}
.top_sec .btn-slider{
    margin-top: 30px;
}
.top_sec  .rightsec .left_circle{
    background: linear-gradient(90.85deg, #DC9834 0.85%, #BF7B0C 53.74%, #D38504 99.44%);
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    left: 35%;
    top: 5%;
}
.top_sec  .rightsec .left_line{
    position: absolute;
    background: url(../images/line_bg.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    left: -10%;
    top: -42%;
}
.top_nft_card .title::before{
    content: '';
    position: absolute;
    background-image: url(../images/nft_element.png);
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    left: -51%;
    top: 6px;
    
}
/* .nftnavscroll{
    height:350px;
    overflow-x: auto;
    padding-left: 20px;
    padding-top: 20px;
} */
.homepage .banner_sec .scroll_arrow{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0%;
}
.top_sec .mobileview .content_sec{
padding-left: unset;
}
.banner_sec .banner_img{
    max-width: 75%;
}
.topnft_sec .top_nft_card .nft_carousel{
    max-width: 280px;
    min-width:280px;
    max-height: 235px;
    min-height: 235px;
    object-fit: cover;
    border-radius: 10px;
}
@media only screen and (max-width: 575px)  {
    .topnft_sec .nav-pills .nav-link img{
    
        width: 45px;
        height: unset;
    }
    .topnft_sec .top_nft_card{
        padding-left: 20px;
    }
    }
@media only screen and (max-width: 575px) {
   
  
    .homepage .banner_sec .social_icons{
        margin-top: unset;
        display: unset;
    }
    .homepage .banner_sec .follow_sec{
        margin-top: 50px;
    }
    .homepage .banner_sec .follow_sec .fl_name{
        margin-left: unset;
    }
    .homepage .banner_sec .social_icons .icons_sec p {

        bottom: 100px;
    }
    .homepage .top_sec .creators_row  .item{
        padding-bottom: 10px;
    }
    .nftnavscroll{
        flex-direction: row !important;
        padding-top: 45px;
        padding-bottom: 45px;
        height: unset;
        overflow-x: unset;
        padding-left: unset;
        justify-content: center;
    }
    .top_nft_card .title::before{
        display: none;
    }
    .topnft_sec .top_nft_card{
        border-radius: 25px;
        margin-right: unset;
    }
    .topnft_sec .top_nft_card .item{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .homepage .top_nft_card .owl-carousel .owl-nav .owl-prev {
        right: -37px;
    }
}
@media only screen and (max-width: 767px)  and (min-width: 576px) {
  
    .homepage .top_sec .mobileview .arrow_sec{
        bottom: -5px;
        top: unset;
    }
    .homepage .top_sec .creators_row  .item{
        padding-bottom: 10px;
    }
    .top_sec .mobileview .content_sec {
        padding-left: 25px;
    }
    .top_sec{
        height: auto;
    }
    .homepage .sc-product.style1 .features .product-media img{
        width: 100% !important;
    }
    .nftnavscroll{
        flex-direction: row !important;
        padding-top: 45px;
        padding-bottom: 45px;
        height: unset;
        overflow-x: unset;
        padding-left: unset;
        justify-content: center;
    }
    .top_nft_card .title::before{
        display: none;
    }
    .topnft_sec .top_nft_card{
        border-radius: 25px;
        margin-right: unset;
    }
    .topnft_sec .top_nft_card .item{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .sc-product .bottom .details-product .current-bid{
        bottom: 10px;
    }
}
@media only screen and (max-width: 991px)  and (min-width: 768px) {
    .nftnavscroll {
        flex-direction: row !important;
        padding-top: 45px;
        padding-bottom: 45px;
        height: unset;
        overflow-x: unset;
        padding-left: unset;
        justify-content: center;
    }
    .top_nft_card .title::before{
        display: none;
    }
    .topnft_sec .top_nft_card{
        border-radius: 25px;
        margin-right: unset;
    }
    .topnft_sec .top_nft_card .item{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 991px)   {
    .homepage .banner_sec{
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 30px;
          height: auto !important;
    }
.top_nft_card .desc{
min-height: unset;
    }
    .top_sec .mobileview .content_sec {
        padding-left: 20px;
    }
    .banner_sec .banner_img {
        max-width: 100%;
    }
    .scroll_arrow{
        display: none;
    }
   
}

.homepage .sc-product.style1 .features .product-media img{
    width: 100% !important;
}
@media only screen and (max-width: 1199px)  and (min-width: 992px) {
    .homepage .banner_sec .banner_row {
        align-items: center;
        padding-top: 140px;
   
    }
    .banner_sec .banner_img {
        max-width: 100%;
    }
    .top_sec .btn-slider {
        margin-top: 15px;
    }
    .top_sec .rightsec .left_circle {
        width: 50px;
        height: 50px;
        left: 32%;
    }
    .top_sec .rightsec .left_line {
      
        left: -7%;
        background-size: 200px;
        top: -25%;
    }
    .top_sec .arrow_sec {
      
        left: 58%;
        top: 40%;
        height: 50px;
        width: 50px;
    }
    .nftnavscroll {
        flex-direction: row !important;
        padding-top: 45px;
        padding-bottom: 45px;
        height: unset;
        overflow-x: unset;
        padding-left: unset;
        justify-content: center;
    }
    .top_nft_card .title::before{
        display: none;
    }
    .topnft_sec .top_nft_card{
        border-radius: 25px;
        margin-right: 0px;
    }
    .homepage .top_nft_card .owl-carousel .owl-nav .owl-prev {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIYSURBVHgB7Zehd8IwEMavexO4IevAbW44cNQxN7m5Sf4mJBKJo644cMi5gaucHKr77jV9hLy0TdoTe6zfe/dC0wv9JZe7pkSdOnXqdDvKsqxPwgpIUAB8QTOG7WFJEAQ/JCARSMD10LzDBlp3ClsB9JtaqjWkCu8HrAgzr15P/WbAZVvQVpAADNG80QXwAIthU9hE9TE0r+iRGqoxJABHaGZ0WTXeg1vtfkQ5bKEY93fUQI0gLQAbAOwtfpUTcZU3pJbBrNpQlm0Jn8x3hrRksDUpGAp9qdFnJpdX5jtBujxETYJDO6q4XzvJRpAu4VKTYJ9QG1q20jwRr8wPagD54XOq2PglqxxqoCtL+CO6TrxlFegdVauvAW4sgEM1iQJwB58F2q02fg6/iT4OPgmaNeUrSdp4q1zC/cR/Zs5UPXimdZl1svK+8mE4TrRPagNZAh7RJVy8GrxHDxY/cz/v4bchT3lBGhnMsu45Y0yr8uMFaclg5xLSZiyrLnF0DbWHHGEL52IMP5VQxbuboUfkqHty1xF2gn2VvX9VtrOltmRAXwyfM+WTPZCjxE7m6jDxqnU1PvWY8gl3ncbG9ZSEJAl5Nq5Fvm9YkpAJXYPF9BfFpYaTR9XT/yXJ7ObVi2CPlJeqROJzluVTJ+vEnxXP6jcX6wfYkgQkmTgD4zokIUlCnozrlIQkGW4+gmWUvxZ5L66pU6cb1S+oFfmdgkCIBwAAAABJRU5ErkJggg==) no-repeat;
        width: 50px;
        height: 50px;
        position: absolute;
        right: -35px;
        top: 33%;
    }

}

@media only screen and (max-width: 1350px)  and (min-width: 1200px) {
.homepage .banner_sec .banner_row {
    align-items: center;
    padding-top: 150px;
}
.banner_sec .banner_img {
    max-width: 85%;
}
.top_sec .rightsec .left_line {
    background-size: 250px;
}
.top_sec .rightsec .left_circle {

    left: 33%;
}
.top_sec .arrow_sec {
    left: 56%;
}
.sc-product .bottom .product-button {
    bottom: 30px;
    right: 22px;
}
.sc-product .bottom .product-button .tf-button{
    padding: 5px;
}
.topnft_sec .top_nft_card{
    margin-right: -6%;
    padding-right: 75px;
}
.top_nft_card .title::before {
    background-size: 250px;
    left: -52%;
    top: 10px;
}
.homepage .top_nft_card .owl-carousel .owl-nav .owl-prev{
    right:-65px;
}

}
@media only screen and (max-width: 1400px)  and (min-width: 1351px) {
.homepage .top_nft_card .owl-carousel .owl-nav .owl-prev {
    right: -55px;
}
.top_sec .rightsec .left_line {

    left: 3%;
    top: -30%;
    background-size: 220px;
}
}
@media only screen and (max-width: 1500px)  and (min-width: 1440px) {
    .homepage .top_nft_card .owl-carousel .owl-nav .owl-prev {
        right: -20px;
    }
    .top_sec .rightsec .left_line {
        left: 7%;
        top: -27%;
        background-size: 220px;
    }
    .topnft_sec .top_nft_card{
        margin-right: -6%;
    }
    .top_sec .rightsec .left_circle {
        left: 37%;
    }
    }

    @media only screen  and (min-width: 1501px) {
        .top_sec .rightsec .left_circle {
            left: 37%;
        }
        .top_sec .rightsec .left_line {
            left: 4%;
            top: -32%;
            background-size: 250px;
        }
        .top_sec .arrow_sec {

            top: 38%;
        }
    }
    @media only screen  and (min-width: 1200px) {
    .topnft_sec .owl-carousel .owl-item.active~.owl-item .item {
        position: absolute;
        top: 250px;
        left: -250px;
    }
    .top_sec .left_sec .pf_big {
        border-radius: 25px 0px;
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
        min-width: 200px;
        max-width: 200px;
    }
}

@media only screen  and (min-width: 1351px) {
.top_nft_card .title::before {
   
    left: -50%;

    background-size: 50% 70%;
}
.topnft_sec .owl-carousel .owl-item.active~.owl-item .item {

    left: -103%;
}
.sc-product .featured-countdown span{
    font-size: 13px;
}
}

@media only screen  and (min-width: 1551px) {
.sc-product .featured-countdown {
    right: 15px;
}
.sc-product .featured-countdown span{
    font-size: 14px;
}
}

@media only screen  and (max-width: 575px) {
.topnft_sec .top_nft_card .nft_carousel{
    max-width: 170px;
    min-width: 170px;
}
}